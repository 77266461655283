import i18n from 'i18next';
import PATHS from '../Routers/PATHS';
import { Consts } from '../res';
import PieChartIcon from '@rsuite/icons/PieChart';
import DashboardIcon from '@rsuite/icons/legacy/Dashboard';
import ProjectIcon from '@rsuite/icons/Project';
import PeoplesIcon from '@rsuite/icons/Peoples';
import CouponIcon from '@rsuite/icons/Coupon';
import GearIcon from '@rsuite/icons/Gear';
import TextImageIcon from '@rsuite/icons/TextImage';
import PeopleSpeakerIcon from '@rsuite/icons/PeopleSpeaker';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import AdminIcon from '@rsuite/icons/Admin';
import SingleSourceIcon from '@rsuite/icons/SingleSource';
import ViewsAuthorizeIcon from '@rsuite/icons/ViewsAuthorize';
import DocPassIcon from '@rsuite/icons/DocPass';
import CalendarIcon from '@rsuite/icons/Calendar';
import ShieldIcon from '@rsuite/icons/Shield';

export const getSideBarData = (isAdmin) => {
    if (isAdmin) return [
        {
            name: i18n.t('General'), icon: <DashboardIcon />, link: null, subs: [
                { name: i18n.t('Dashboard'), icon: 'dashboard', link: PATHS.ADMIN_DASHBOARD.URL },
                { name: i18n.t('Announcements'), icon: 'newspaper', link: PATHS.ADMIN_NEWS.URL },
                { name: i18n.t('Instructors'), icon: 'id badge', link: PATHS.ADMIN_INSTRUCTORS.URL },
                { name: i18n.t('LearningTeam'), icon: 'chess', link: PATHS.ADMIN_TEAM_MEMBERS.URL },
                { name: i18n.t('SuccessStories'), icon: 'child', link: PATHS.ADMIN_SUCCESS_STORIES.URL },
                { name: i18n.t('HonorRoll'), icon: 'trophy', link: PATHS.ADMIN_HONOR_ROLL.URL },
                { name: i18n.t('Calendar'), icon: 'calendar alternate', link: PATHS.ADMIN_COURSES_CALENDAR.URL },
                { name: i18n.t('Messages'), icon: 'envelope', link: PATHS.ADMIN_MESSAGES.URL },

            ]
        },
        {
            name: i18n.t('HR'), icon: <PeoplesIcon />, link: null, subs: [
                { name: i18n.t('Permissions'), icon: 'lock', link: PATHS.ADMIN_ROLES.URL },
                { name: i18n.t('Admins'), icon: 'user secret', link: PATHS.ADMIN_ADMINS.URL },
                { name: i18n.t('Users'), icon: 'users', link: PATHS.ADMIN_USERS.URL },
            ]
        },
        {
            name: i18n.t('Finance'), color: 'red', icon: <CouponIcon />, link: null, subs: [
                { name: i18n.t('Packages'), color: 'red', icon: 'boxes', link: PATHS.ADMIN_PACKAGES.URL },
                { name: i18n.t('Promotions'), icon: 'percent', link: PATHS.ADMIN_PROMOTIONS.URL },
                { name: i18n.t('Subscriptions'), icon: 'dollar', link: PATHS.ADMIN_SUBSCRIPTIONS.URL },
            ]
        },
        {
            name: i18n.t('Programs'), color: 'red', icon: <ProjectIcon />, link: null, subs: [

                { name: i18n.t('OneDayCourses'), color: 'brown', icon: 'video play', link: PATHS.ADMIN_ONE_DAY_COURSES.URL },
                { name: i18n.t('Certifications'), color: 'red', icon: 'certificate', link: PATHS.ADMIN_CERTIFICATIONS.URL },
                { name: i18n.t('Courses'), color: 'red', icon: 'graduation', link: PATHS.ADMIN_COURSES.URL },
                { name: i18n.t('Units'), color: 'red', icon: 'file alternate outline', link: PATHS.ADMIN_UNITS.URL },
                { name: i18n.t('Topics'), color: 'red', icon: 'sort alphabet down', link: PATHS.ADMIN_TOPICS.URL },
                { name: i18n.t('Uploads'), icon: 'upload', link: PATHS.ADMIN_UPLOADS.URL },
            ]
        },
        {
            name: i18n.t('Questions'), icon: <HelpOutlineIcon />, link: null, subs: [
                { name: i18n.t('MCQS'), icon: 'list ol', link: PATHS.ADMIN_QUESTIONS_MCQS.URL },
                { name: i18n.t('Essay'), icon: 'edit outline', link: PATHS.ADMIN_QUESTIONS_ESSAY.URL },
                // { name: i18n.t('Text'), icon: 'comment outline', link: '/admin/questions/text' },
                { name: i18n.t('Composite'), icon: 'sort amount down', link: PATHS.ADMIN_QUESTIONS_COMPOSITE.URL },
                { name: i18n.t('FileQuestions'), icon: 'file code outline', link: PATHS.ADMIN_QUESTIONS_FILE.URL },
                { name: i18n.t('Sizes'), icon: 'object ungroup outline', link: PATHS.ADMIN_QUESTIONS_SIZES.URL },
                { name: i18n.t('Categories'), icon: 'th', link: PATHS.ADMIN_QUESTIONS_CATEGORIES.URL },
                { name: i18n.t('Difficulties'), icon: 'chart line', link: PATHS.ADMIN_QUESTIONS_DIFFICULTIES.URL },
            ]
        },
        {
            name: i18n.t('Reports'), icon: <PieChartIcon />, link: null, subs: [
                { name: i18n.t('Payments'), icon: 'payment', link: PATHS.ADMIN_REPORTS_PAYMENTS.URL },
                { name: i18n.t('ManualPayments'), icon: 'payment', link: PATHS.ADMIN_MANUAL_PAYMENTS.URL },
                { name: i18n.t('UserPerformance'), icon: 'chart pie', link: PATHS.ADMIN_USER_PERFORMANCE.URL },


            ]
        },
        {
            name: i18n.t('ContentReports'), icon: <PeopleSpeakerIcon />, link: null, subs: [
                { name: i18n.t('Questions'), icon: 'question', link: `${PATHS.ADMIN_REPORTS_CONTENTS.URL.substring(0, PATHS.ADMIN_REPORTS_CONTENTS.URL.lastIndexOf('/'))}/${Consts.REPORTS_TYPES.QUESTION}` },
                { name: i18n.t('Videos'), icon: 'video', link: `${PATHS.ADMIN_REPORTS_CONTENTS.URL.substring(0, PATHS.ADMIN_REPORTS_CONTENTS.URL.lastIndexOf('/'))}/${Consts.REPORTS_TYPES.VIDEO}` },
            ]
        },
        {
            name: i18n.t('Exams'), icon: <TextImageIcon />, link: null, subs: [
                { name: i18n.t('ExamsManagement'), icon: 'file alternate outline', link: PATHS.ADMIN_EXAMS.URL },
                { name: i18n.t('MockExams'), icon: 'file alternate outline', link: PATHS.ADMIN_MOCK_EXAMS.URL },
                { name: i18n.t('ExamCorrection'), icon: 'gavel', link: PATHS.ADMIN_EXAM_CORRECTION.URL },
            ]
        },
        {
            name: i18n.t('Settings'), icon: <GearIcon />, link: null, subs: [
                { name: i18n.t('SignInLogs'), icon: 'exclamation', link: PATHS.ADMIN_SETTINGS_IPLOGS.URL },
                { name: i18n.t('Notifications'), icon: 'bell', link: PATHS.ADMIN_SETTINGS_NOTIFICATIONS.URL },
                { name: i18n.t('Policies'), icon: 'privacy', link: PATHS.ADMIN_POLICY_TERMS.URL },
            ]
        },
    ];

    if (Consts.HIDE_MODE.MENUS) {
        return [
            { id: 1, name: i18n.t('MyAccount'), icon: <AdminIcon />, link: PATHS.USER_DASHBOARD.URL },
            // { id: 5, name: i18n.t('Notifications'), icon: <NoticeIcon />, link: PATHS.USER_NOTIFICATIONS.URL },
            { id: 7, name: i18n.t('HonorRoll'), icon: <ShieldIcon />, link: PATHS.LANDING_HONOR.URL },
            { id: 6, name: i18n.t('Support'), icon: <HelpOutlineIcon />, link: PATHS.USER_SUPPORT.URL },
    
            // { name: i18n.t('Course'), icon: 'graduation', link: '/user/course' },
            // { name: i18n.t('McqsBank'), icon: 'tasks', link: '/user/msqsbank' },
            // { name: i18n.t('EssaysBank'), icon: 'edit outline', link: '/user/essaysbank' },
            // { name: i18n.t('MockExam'), icon: 'file alternate outline', link: '/user/mockexam' },
            // { name: i18n.t('ExamsLog'), icon: 'history', link: '/user/exam/log' },
            // { name: i18n.t('Performance'), icon: 'chart pie', link: '/user/performance' },
        ];
    }
    return [
        { id: 1, name: i18n.t('MyAccount'), icon: <AdminIcon />, link: PATHS.USER_DASHBOARD.URL },
        { id: 2, name: i18n.t('Calendar'), icon: <CalendarIcon />, link: PATHS.LANDING_CALENDAR.URL },
        { id: 3, name: i18n.t('CertificatesCurriculums'), icon: <ViewsAuthorizeIcon />, link: PATHS.LANDING_CERTIFICATIONS.URL },
        { id: 4, name: i18n.t('AvailableCourses'), icon: <SingleSourceIcon />, link: PATHS.LANDING_COURSES.URL },
        { id: 5, name: i18n.t('Subscriptions'), icon: <DocPassIcon />, link: PATHS.USER_SUBSCRIPTIONS.URL },
        // { id: 5, name: i18n.t('Notifications'), icon: <NoticeIcon />, link: PATHS.USER_NOTIFICATIONS.URL },
        { id: 6, name: i18n.t('Support'), icon: <HelpOutlineIcon />, link: PATHS.USER_SUPPORT.URL },
        { id: 7, name: i18n.t('HonorRoll'), icon: <ShieldIcon />, link: PATHS.LANDING_HONOR.URL },

        // { name: i18n.t('Course'), icon: 'graduation', link: '/user/course' },
        // { name: i18n.t('McqsBank'), icon: 'tasks', link: '/user/msqsbank' },
        // { name: i18n.t('EssaysBank'), icon: 'edit outline', link: '/user/essaysbank' },
        // { name: i18n.t('MockExam'), icon: 'file alternate outline', link: '/user/mockexam' },
        // { name: i18n.t('ExamsLog'), icon: 'history', link: '/user/exam/log' },
        // { name: i18n.t('Performance'), icon: 'chart pie', link: '/user/performance' },
    ];
};