import React, { useState, useEffect } from 'react';
import { FileUploader } from 'devextreme-react/ui/file-uploader';
import { Grid, GridColumn, Icon, Segment } from 'semantic-ui-react';
import { BASE_URL } from '../../api';

const VideoUploader = ({ data, Url }) => {
    const uploadUrl = Url ? `${BASE_URL}${Url}` : `${BASE_URL}/video/upload`;
    const [loading, setLoading] = useState(false);
    const allowedFileExtensions = ['.mp4'];

    useEffect(() => {
        let open = XMLHttpRequest.prototype.open;
        XMLHttpRequest.prototype.open = function () {
            open.apply(this, arguments);
            this.withCredentials = true;
        };
    }, []);

    const onUploaded = (e) => {
        const file = e.file;
        const fileReader = new FileReader();
        fileReader.onload = () => {
            setLoading(false);
        };
        fileReader.readAsDataURL(file);

        const response = JSON.parse(e.request.response);
        if (data.topicId)
            data.setValue({ urlEn: response.videoUrl, sizeInBytesEn: response.sizeInBytes, topicId: data.topicId });
        if (data.questionId)
            data.setValue({ urlEn: response.videoUrl, sizeInBytesEn: response.sizeInBytes, questionId: data.questionId });
        if (data.isExamDemo)
            data.setValue({ urlEn: response.videoUrl, sizeInBytesEn: response.sizeInBytes });
    };

    return (
        <div>
            <Grid stackable columns={2}>
                <GridColumn textAlign='center' verticalAlign='middle'>
                    <FileUploader
                        id="file-uploader"
                        multiple={false}
                        accept="video/mp4"
                        allowedFileExtensions={allowedFileExtensions}
                        uploadMode="instantly"
                        uploadUrl={uploadUrl}
                        visible
                        disabled={!data.topicId && !data.questionId && !data.isExamDemo}
                        allowCanceling
                        name='video'
                        // onUploadStarted={() => setLoading(true)}
                        onUploaded={onUploaded}
                        // onUploadAborted={() => setLoading(false)}
                        // onUploadError={() => setLoading(false)}
                    />
                </GridColumn>

                <GridColumn textAlign='center'>
                    {
                        loading ? <Segment size='huge' basic placeholder loading /> :
                            <Icon name='upload' circular size='huge' />
                    }
                </GridColumn>
            </Grid>
        </div>
    );
};



export default VideoUploader;