import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Header, Segment } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { userOnStartExam, userOnRetryExam, userClearOnGoingExam, userGetOnGoingExam, userExamInfoChanged, userGetSelectedCourseStruct } from '../../../actions';
import { Consts } from '../../../res';
import McqsResult from './McqsResult';
import EssayResult from './EssayResult';
import MockResultTemplate from './MockResultTemplates';
import LastMockResult from './LastMockResult';
import Separator from '../../Operantinals/Separator';
import { findNextFreqExam, getExamByExamId, resolveExam } from '../../../helpers/examHelpers';
import history from '../../../history';
import PATHS from '../../../Routers/PATHS';

const calcParts = (theme) => {
    switch (theme) {
        case Consts.EXAM_THEMES.CPA: return 5;
        case Consts.EXAM_THEMES.CIA:
        case Consts.EXAM_THEMES.DIFR: return 1;
        default: return 2;
    }
};

const Results = (props) => {
    const { onGoingExam, t, userClearOnGoingExam, userOnStartExam, userExamInfoChanged, userGetOnGoingExam,
        selectedCourse, userGetSelectedCourseStruct, userOnRetryExam, summary } = props;

    const maxMockParts = calcParts(onGoingExam.theme);
    const examCurrentType = onGoingExam.examType.includes("MOCK") ? parseInt(onGoingExam.examType[onGoingExam.examType.length - 1]) : -1;
    const isLastMockPart = examCurrentType === maxMockParts || examCurrentType === -1;

    const to = () => {
        switch (onGoingExam.examType) {
            case Consts.EXAM_TYPES.MCQS:
                return PATHS.USER_BANK_MCQS.URL;
            case Consts.EXAM_TYPES.ESSAY:
                return PATHS.USER_BANK_ESSAY.URL;
            case Consts.EXAM_TYPES.TBS:
                return PATHS.USER_BANK_TBS.URL;
            case Consts.EXAM_TYPES.FILE:
                return PATHS.USER_BANK_FILE.URL;
            case Consts.EXAM_TYPES.TEST_MCQS:
            case Consts.EXAM_TYPES.TEST_ESSAY:
            case Consts.EXAM_TYPES.FREQ:
                return PATHS.USER_COURSE_DETAILS.URL;
            default:
                return PATHS.USER_DASHBOARD.URL;
        }
    };

    const onMoveToNextPart = async () => {
        userExamInfoChanged({ props: 'questions', value: [] });
        let nextExam;
        if (onGoingExam.examType === Consts.EXAM_TYPES.FREQ) {
            const nextFreq = findNextFreqExam(onGoingExam.parentId, onGoingExam.orderNum);
            nextExam = resolveExam(onGoingExam.examType, null, nextFreq.id);
        } else {
            const lastMock = parseInt(onGoingExam.examType[onGoingExam.examType.length - 1]);
            nextExam = resolveExam(onGoingExam.examType.slice(0, onGoingExam.examType.length - 1) + (1 + lastMock));
        }
        if (nextExam) {
            await userOnStartExam(nextExam, nextExam?.questionsCount || 1);
            if (summary.show) summary.setShowSummary(false);
            userExamInfoChanged({ props: 'isFinished', value: false });
        }
        else {
            userExamInfoChanged({ props: 'isFinished', value: true });
            history.push(to());
        }
    };

    const renderResultSwitch = () => {
        if (onGoingExam.isViewOnly) return <Header as='h1' textAlign='center'>{t('YourViewHasFinished')}</Header>;
        switch (onGoingExam.examType) {
            case Consts.EXAM_TYPES.MCQS:
            case Consts.EXAM_TYPES.TEST_MCQS:
                return <McqsResult onGoingExam={onGoingExam} onGetOngoingExam={userGetOnGoingExam} onRetryExam={userOnRetryExam} userExamInfoChanged={userExamInfoChanged} />;
            case Consts.EXAM_TYPES.ESSAY:
            case Consts.EXAM_TYPES.TEST_ESSAY:
                return <EssayResult />;
            case Consts.EXAM_TYPES.MOCK_PART1:
            case Consts.EXAM_TYPES.MOCK_PART2:
            case Consts.EXAM_TYPES.MOCK_PART3:
            case Consts.EXAM_TYPES.MOCK_PART4:
            case Consts.EXAM_TYPES.MOCK_PART5:
                return isLastMockPart && onGoingExam.theme !== Consts.EXAM_THEMES.CIA ?
                    <LastMockResult onGoingExam={onGoingExam} /> : <MockResultTemplate examType={onGoingExam.examType} theme={onGoingExam.theme} onMoveToEssay={onMoveToNextPart} examRoundId={onGoingExam.examRoundId} />;
            case Consts.EXAM_TYPES.FREQ: {
                if (!onGoingExam.parentId) return <LastMockResult onGoingExam={onGoingExam} />;
                if (getExamByExamId(onGoingExam?.parentId)?.childs?.length === onGoingExam.orderNum) return <LastMockResult onGoingExam={onGoingExam} />;
                return <MockResultTemplate examType={onGoingExam.examType} theme={onGoingExam.theme} onMoveToEssay={onMoveToNextPart} examRoundId={onGoingExam.examRoundId} />;
            }
            default: return <Header as='h1' textAlign='center'>{t('YourExamHasFinished')}</Header>;
        }
    };

    return (
        <Segment basic>
            <Container style={{ width: '90vw' }}>
                {renderResultSwitch()}
                <Separator />
                {(isLastMockPart || onGoingExam.isViewOnly) && <Segment basic textAlign='center'>
                    <Button primary labelPosition='left' icon='arrow left' content={t('Back')} as={Link} to={to} onClick={async () => {
                        if (onGoingExam.examType === Consts.EXAM_TYPES.TEST_MCQS || onGoingExam.examType === Consts.EXAM_TYPES.TEST_ESSAY || onGoingExam.examType === Consts.EXAM_TYPES.TEST_TBS || onGoingExam.examType === Consts.EXAM_TYPES.TEST_FILE || onGoingExam.examType === Consts.EXAM_TYPES.FREQ)
                            await userGetSelectedCourseStruct(selectedCourse.id);
                        userClearOnGoingExam();
                    }} />
                </Segment>}
            </Container>
        </Segment>

    );
};

const mapStateToProps = ({ userCourses }) => {
    const { selectedCourse } = userCourses;
    return { selectedCourse };
};

export default withTranslation()(connect(mapStateToProps, { userOnStartExam, userOnRetryExam, userClearOnGoingExam, userGetOnGoingExam, userExamInfoChanged, userGetSelectedCourseStruct })(Results));