import React, { useState } from 'react';
import { Input, Icon } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import '../../../res/css/modal-draggable.css';
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';
// import pdfFile from '../../../res/pdfs/Information.pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/js/pdf.worker.min.js`;

const TBSAttachmentModal = ({ title, docUrl, open, closeModalAction, index, t }) => {
    const [numPages, setNumPages] = useState(null);
    const [scale, setScale] = useState(1);
    const [width, setWidth] = useState(200);
    const [height, setHeight] = useState(500);

    const onResize = (event, { node, size, handle }) => {
        setWidth(size.width);
        setHeight(size.height);
    };

    const onDocumentLoadSuccess = (pdf) => {
        setNumPages(pdf.numPages);
    };

    if (!open)
        return null;

    return (
        <Draggable cancel={".zoom-bar, .react-resizable-handle"}>
            <Resizable height={height} width={width} onResize={onResize}>
                <div style={{ position: 'absolute', top: '5%', left: '50%' }}>
                    <div className='pdf-modal-header'>
                        <span id="ui-id-23" className="ui-dialog-title">{title}</span>
                        <button className="driver-dialog-titlebar-close" onClick={() => {
                            closeModalAction(index);
                        }}><span aria-label="Close" >×</span></button>
                    </div>
                    <div style={{ backgroundColor: '#084f69' }} className='zoom-bar' >
                        <Icon
                            style={{ margin: '5px' }}
                            name='search plus'
                            onClick={() => {
                                setScale(scale + 0.1);
                            }}
                            color='grey'
                        />
                        <Input
                            style={{ margin: '5px' }}
                            type='number'
                            min={25} max={1000}
                            value={(Number(scale * 100)).toFixed(0)}
                            onChange={(e, { value }) => {
                                setScale(value / 100);
                            }}
                        />
                        <Icon
                            style={{ margin: '5px' }}
                            name='search minus'
                            onClick={() => {
                                setScale(scale - 0.1);
                            }}
                            color='grey'
                        />
                    </div>
                    <div className='box' style={{
                        overflow: 'scroll',
                        width: width + 'px', height: height + 'px'
                    }}>
                        <Document
                            //file='https://cdn.filestackcontent.com/wcrjf9qPTCKXV3hMXDwK' 
                            file={docUrl}
                            //file={pdfFile}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            {Array.from(
                                new Array(numPages),
                                (el, index) => (
                                    <Page
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                        scale={scale}
                                        onLoadSuccess={(page) => {
                                            if (index === 0) {
                                                setWidth(page.width);
                                                setHeight(page.height > 570 ? 570 : page.height);
                                            }
                                        }}
                                    />
                                ),
                            )}
                        </Document>
                    </div>
                </div>
            </Resizable>
        </Draggable>
    );
};

export default withTranslation()(TBSAttachmentModal);