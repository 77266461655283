import i18n from 'i18next';
import notify from "devextreme/ui/notify";
import _ from 'lodash';
import {
    USER_EXAM_INFO_CHANGED,
    USER_GET_EXAM_QUESTIONS_START,
    USER_GET_EXAM_QUESTIONS_FAIL,
    USER_GET_EXAM_QUESTIONS_FINISH,
    USER_GET_EXAM_QUESTIONS,
    USER_GET_EXAM_QUESTION_OPTIONS_START,
    USER_GET_EXAM_QUESTION_OPTIONS_FAIL,
    USER_GET_EXAM_QUESTION_OPTIONS_FINISH,
    USER_EXAM_ANSWER_QUESTION,
    USER_EXAM_FLAG_QUESTION,
    USER_EXAM_SELECT_QUESTION,
    USER_GET_ONGOING_EXAM,
    USER_GET_ONGOING_EXAM_FAIL,
    USER_GET_ONGOING_EXAM_FINISH,
    USER_GET_ONGOING_EXAM_START,
    USER_ON_EXAM_START,
    USER_ON_EXAM_FINISH,
    USER_ON_EXAM_START_LOADING_START,
    USER_ON_EXAM_START_LOADING_FINISH,
    USER_ON_EXAM_FINISH_LOADING_START,
    USER_ON_EXAM_FINISH_LOADING_FINISH
} from '../types';

import request from '../../api';
import { resolveExam } from '../../helpers/examHelpers';
import { Consts } from '../../res';

export const userExamInfoChanged = ({ props, value }) => {
    return { type: USER_EXAM_INFO_CHANGED, payload: { props, value } };
};

export const userGetOnGoingExam = (examType, topic, examId) => async dispatch => {
    const exam = resolveExam(examType, topic, examId);
    if (!exam) return;

    dispatch({ type: USER_GET_ONGOING_EXAM_START });
    try {
        const isMockExam = examType.includes("MOCK") || examType === Consts.EXAM_TYPES.FREQ;
        let examRounds = [];

        const { data: { data } } = await request.get(`/student/round/exam/${exam.id}/ongoing`);
        examRounds = data;

        dispatch({ type: USER_GET_ONGOING_EXAM, payload: examRounds.map(e => ({ ...exam, ...e, examType, isMockExam, isOld: true, isViewOnly: false, theme: exam.theme })) });
    } catch (err) {
        if (err.response) dispatch({ type: USER_GET_ONGOING_EXAM_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: USER_GET_ONGOING_EXAM_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: USER_GET_ONGOING_EXAM_FINISH });
    }
};

export const userClearOnGoingExam = () => {
    return { type: USER_ON_EXAM_FINISH };
};

export const userGetExamQuestions = (examRoundId) => async dispatch => {
    dispatch({ type: USER_GET_EXAM_QUESTIONS_START });
    try {
        const { data: { data } } = await request.get(`/student/round/exam/questions/${examRoundId}`);
        dispatch({ type: USER_GET_EXAM_QUESTIONS, payload: data });
    } catch (err) {
        if (err.response) dispatch({ type: USER_GET_EXAM_QUESTIONS_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: USER_GET_EXAM_QUESTIONS_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: USER_GET_EXAM_QUESTIONS_FINISH });
    }
};

export const userExamSelectQuestion = (Q) => async (dispatch, getState) => {
    dispatch({ type: USER_GET_EXAM_QUESTION_OPTIONS_START });
    try {
        const promise1 = userGetExamQuestionOptions(Q)(dispatch);
        const promise2 = userExamGetCorrectAnswer(Q)(dispatch, getState);
        const promise3 = userGetExamQuestionAttachments(Q)(dispatch);
        const data = await Promise.all([promise1, promise2, promise3]);
        dispatch({ type: USER_EXAM_SELECT_QUESTION, payload: { ...Q, options: data[0], correctAnswer: data[1], attachments: data[2] } });
    } catch (err) {

    } finally {
        dispatch({ type: USER_GET_EXAM_QUESTION_OPTIONS_FINISH });
    }
};


export const userGetExamQuestionOptions = (Q) => async dispatch => {
    if (Q.questionTypeId !== Consts.QUESTIONS_TYPES.MCQS)
        return null;

    try {
        if (Q.options) return Q.options;
        const { data: { data } } = await request.get(`/student/question/${Q.questionId}/options`);
        return _.shuffle(data);
    } catch (err) {
        if (err.response) dispatch({ type: USER_GET_EXAM_QUESTION_OPTIONS_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: USER_GET_EXAM_QUESTION_OPTIONS_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    }
};

export const userGetExamQuestionAttachments = (Q) => async dispatch => {
    if (Q.questionTypeId === Consts.QUESTIONS_TYPES.COMPOSITE || Q.questionTypeId === Consts.QUESTIONS_TYPES.File) {

        try {
            if (Q.attachments) return Q.attachments;
            const { data: { data } } = await request.get(`/student/question/${Q.questionId}/docs`);
            return data;
        } catch (err) {
            if (err.response) dispatch({ type: USER_GET_EXAM_QUESTION_OPTIONS_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
            else dispatch({ type: USER_GET_EXAM_QUESTION_OPTIONS_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
        }
    }
    else return null;
};

export const userExamGetCorrectAnswer = (Q) => async (dispatch, getState) => {
    if (Q.correctAnswer) return Q.correctAnswer;
    const { isMockExam, isViewOnly } = getState().userExam.onGoingExam;
    if (isMockExam && !isViewOnly) return {};
    try {
        const { data: { data } } = await request.post('/student/round/question/answer/correction', {
            questionRoundId: Q.userQuestionRoundId,
            answerOptionId: Q.answerOptionId,
            answerText: Q.answerText
        });
        return data;
    } catch (err) {
        if (err.response) dispatch({ type: USER_GET_EXAM_QUESTIONS_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: USER_GET_EXAM_QUESTIONS_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    }
};

export const userExamFlagQuestion = (Q) => async dispatch => {
    Q.isFlagged = !Q.isFlagged;
    try {
        dispatch({ type: USER_EXAM_FLAG_QUESTION, payload: Q });
        await request.post('/student/round/question/flag', {
            questionRoundId: Q.userQuestionRoundId,
            isFlagged: Q.isFlagged
        });
    } catch (err) {
        if (err.response) dispatch({ type: USER_GET_EXAM_QUESTIONS_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: USER_GET_EXAM_QUESTIONS_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    }
};

export const userExamAnswerQuestion = (Q, isSubmited = false) => async dispatch => {
    try {
        dispatch({ type: USER_EXAM_ANSWER_QUESTION, payload: isSubmited ? { ...Q, isSubmited } : Q });
        await request.post('/student/round/question/answer', {
            questionRoundId: Q.userQuestionRoundId,
            answerOptionId: Q.answerOptionId,
            answerText: Q.answerText,
            answerJson: Q.answerJson,
            isSubmited
        });
    } catch (err) {
        if (err.response) dispatch({ type: USER_GET_EXAM_QUESTIONS_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: USER_GET_EXAM_QUESTIONS_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    }
};

export const userOnStartExam = (exam, questionsCount, topicsIds, isRandomQuestionsOrder, examName) => async dispatch => {
    dispatch({ type: USER_ON_EXAM_START_LOADING_START });
    try {
        const { data: { examRoundId, finishAt, mock1LinkAllQuestionsCnt, mock1LinkPassQuestionsCnt } } = await request.post('/student/round/exam/start', { examId: exam.id, questionsCount, topicsIds, isRandomQuestionsOrder, name: examName });
        const newExam = {
            ...exam,
            examName,
            questionsCount,
            examId: exam.id,
            examRoundId: examRoundId,
            examType: exam.type,
            isPassed: false,
            isOld: false,
            isViewOnly: false,
            theme: exam.theme,
            startDateTime: new Date().toISOString(),
            finishDateTime: finishAt || new Date(new Date().getTime() + (1000 * 60 * 60 * 3)).toISOString(),
            isMockExam: exam.type.includes('MOCK') || exam.type === Consts.EXAM_TYPES.FREQ
        };
        dispatch({ type: USER_ON_EXAM_START, payload: newExam });
        if (exam.type === Consts.EXAM_TYPES.MOCK_PART2) {
            if (parseInt(mock1LinkAllQuestionsCnt / 2) > mock1LinkPassQuestionsCnt)
                notify({ message: i18n.t('MockNot50Text'), position: 'top center' }, "error", 10000);
        }
    } catch (err) {
        if (err.response) dispatch({ type: USER_GET_ONGOING_EXAM_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: USER_GET_ONGOING_EXAM_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: USER_ON_EXAM_START_LOADING_FINISH });
    }
};

export const userOnRetryExam = (exam, bIncludeWrongQ, bIncludeFlaggedQ, bIncludeUnanswerQ) => async dispatch => {
    dispatch({ type: USER_ON_EXAM_START_LOADING_START });
    try {
        const { data } = await request.post('/student/round/exam/retry', { examRoundId: exam.examRoundId, bIncludeWrongQ, bIncludeFlaggedQ, bIncludeUnanswerQ });
        return data;
    } catch (err) {
        if (err.response) dispatch({ type: USER_GET_ONGOING_EXAM_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: USER_GET_ONGOING_EXAM_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: USER_ON_EXAM_START_LOADING_FINISH });
    }
};

export const userOnViewOnlyExam = (exam) => dispatch => {
    dispatch({ type: USER_ON_EXAM_START_LOADING_START });
    const newExam = {
        questionsCount: exam.questionsCount,
        examId: exam.id,
        examRoundId: exam.examRoundId,
        examType: exam.examType,
        theme: exam.examTheme,
        isPassed: false,
        isOld: false,
        isViewOnly: true,
        startDateTime: new Date().toISOString(),
        finishDateTime: exam.finishAt,
        isMockExam: false
    };
    dispatch({ type: USER_ON_EXAM_START, payload: newExam });
    dispatch({ type: USER_ON_EXAM_START_LOADING_FINISH });
};

export const userOnFinishExam = (exam) => async dispatch => {
    dispatch({ type: USER_ON_EXAM_FINISH_LOADING_START });
    try {
        if (!exam.isViewOnly)
            await request.post('/student/round/exam/finish', { examRoundId: exam.examRoundId });
        dispatch({ type: USER_EXAM_INFO_CHANGED, payload: { props: 'isFinished', value: true } });
    } catch (err) {
        if (err.response) dispatch({ type: USER_GET_ONGOING_EXAM_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: USER_GET_ONGOING_EXAM_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: USER_ON_EXAM_FINISH_LOADING_FINISH });
    }
};