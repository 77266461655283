import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Image, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { htmlToText } from 'html-to-text';
import { BuyingButton } from '..';
import stringShorten from '../../helpers/stringShorten';
import { Styles, Theme } from '../../res';
import placeholderImage from '../../res/images/course.jpg';
import PATHS from '../../Routers/PATHS';
import { Media } from '../../Media';


const { solidBtn, f_16_700_text, f_16_400_text, f_12_400_text } = Styles;

const CourseCard = ({ data }) => {
    const { t } = useTranslation();
    const { id, name, coverImgUrl, priceUSD, discountUSD, description, idSubscriptionPackage } = data;
    const [selected, setSelected] = useState(false);

    const getPricing = () => {
        if ((priceUSD - discountUSD) <= 0) return 0;
        return discountUSD ? `$${(priceUSD - discountUSD).toFixed(0)}` : `$${(priceUSD).toFixed(0)}`;
    };

    return (
        <>
            <Media greaterThanOrEqual='tablet'>
                <Card
                    className='animationCardScale'
                    style={{ margin: 0, padding: 8, height: '45vh', borderRadius: 8, boxShadow: 'none', backgroundColor: selected ? Theme.colors.PRIMARY_50 : Theme.colors.WHITE, cursor: 'pointer' }}
                    onMouseEnter={() => setSelected(true)}
                    onMouseLeave={() => setSelected(false)}
                >

                    <Image
                        src={coverImgUrl || placeholderImage}
                        label={getPricing() !== 0 ? null : { ribbon: true, style: { ...f_16_400_text, color: Theme.colors.WHITE, backgroundColor: Theme.colors.SECONDARY_50 }, content: t('Free').toUpperCase() }}
                        style={{ display: 'flex', height: 150, width: null }}
                        fluid
                    />
                    <Card.Content style={{ borderTop: 'none', paddingBottom: 5, display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                        <Card.Header style={{ ...f_16_700_text, color: selected ? Theme.colors.WHITE : Theme.colors.BLACK, whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: 'auto', overflow: 'hidden' }}>
                            {name}
                        </Card.Header>

                        {selected && <Card.Meta style={{ ...f_12_400_text, color: selected ? Theme.colors.WHITE : Theme.colors.BLACK }}>
                            {stringShorten(htmlToText(description, { wordwrap: null }), 100)}
                        </Card.Meta>}

                        <Card.Description>
                            <div
                                style={{ display: 'flex', gap: '.5vw', alignItems: 'center', justifyContent: 'space-between' }}
                            >
                                <Button as={Link} size='small' to={`${PATHS.LANDING_COURSES.URL}/${id}/${idSubscriptionPackage}`} content={t('Details').toUpperCase()} style={{ ...solidBtn, backgroundColor: Theme.colors.PRIMARY_05, color: Theme.colors.PRIMARY_50 }} fluid />
                                <BuyingButton mode="PRICE_AS_BUTTON" selectedForHover={selected} data={data} />
                            </div>
                        </Card.Description>
                    </Card.Content>
                </Card>
            </Media>

            <Media lessThan='tablet'>
                <div style={{ margin: "0 5vw" }}>
                    <Card
                        className='animationCardScale'
                        style={{ margin: 0, padding: 8, width: "100%", borderRadius: 8, boxShadow: 'none', backgroundColor: selected ? Theme.colors.PRIMARY_50 : Theme.colors.WHITE, cursor: 'pointer' }}
                        onMouseEnter={() => setSelected(true)}
                        onMouseLeave={() => setSelected(false)}
                    >

                        <Image
                            src={coverImgUrl || placeholderImage}
                            label={getPricing() !== 0 ? null : { ribbon: true, style: { ...f_16_400_text, color: Theme.colors.WHITE, backgroundColor: Theme.colors.SECONDARY_50 }, content: t('Free').toUpperCase() }}
                            style={{ display: 'flex' }}
                            fluid
                        />
                        <Card.Content style={{ borderTop: 'none', paddingBottom: 5, display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                            <Card.Header style={{ ...f_16_700_text, color: selected ? Theme.colors.WHITE : Theme.colors.BLACK }}>
                                {name}
                            </Card.Header>

                            {selected && <Card.Meta style={{ ...f_12_400_text, color: selected ? Theme.colors.WHITE : Theme.colors.BLACK }}>
                                {stringShorten(htmlToText(description, { wordwrap: null }), 100)}
                            </Card.Meta>}

                            <Card.Description>
                                <div
                                    style={{ display: 'flex', gap: '.5vw', alignItems: 'center', justifyContent: 'space-between' }}
                                >
                                    <Button as={Link} size='small' to={`${PATHS.LANDING_COURSES.URL}/${id}/${idSubscriptionPackage}`} content={t('Details').toUpperCase()} style={{ ...solidBtn, backgroundColor: Theme.colors.PRIMARY_05, color: Theme.colors.PRIMARY_50 }} fluid />
                                    <BuyingButton mode="PRICE_AS_BUTTON" selectedForHover={selected} data={data} />
                                </div>
                            </Card.Description>
                        </Card.Content>
                    </Card>
                </div>
            </Media>
        </>

    );
};

export default CourseCard;
