import React, { useMemo } from 'react';
import { Header, Icon } from 'semantic-ui-react';
import Draggable from 'react-draggable';
import { Styles } from '../../../res';

const { f_16_700_text } = Styles;

const FreeModal = ({ title, onClose, open, children, fileKey }) => {

    const top = useMemo(() => `${Math.ceil(Math.random() * 30) + 5}%`, []);
    const left = useMemo(() => `${Math.floor(Math.random() * 20) + 5}%`, []);

    if (!open || open === 0) return null;

    return (
        <Draggable bounds='parent' handle="#handle" cancel={"input, svg, button, table"} enableUserSelectHack={false}>
            <div style={{ zIndex: open, border: 'solid 1px #ccc', position: 'absolute', top, left, backgroundColor: 'white' }}>
                <div style={{ resize: 'both', display: 'flex', flexDirection: 'column', minHeight: '75vh', minWidth: '70vw', overflow: 'auto', position: 'relative' }}>
                    <div id="handle" style={{ padding: 5, zIndex: 10, cursor: 'move', position: 'sticky', top: 0, backgroundColor: '#dcdddd', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            <Header style={f_16_700_text}>{title}</Header>
                        </div>

                        <div>
                            <Icon
                                link name='x'
                                onClick={() => onClose(prev => {
                                    const temp = { ...prev };
                                    delete temp[fileKey];
                                    return { ...temp };
                                })}
                            />
                        </div>
                    </div>
                    <div style={{ borderTop: 'solid 1px #ccc' }} />
                    <div style={{ position: 'relative', flexGrow: 1 }}>
                        <div style={{ position: 'absolute', inset: 0, padding: 20 }}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </Draggable>
    );
};

export default FreeModal;