import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { RequireAuth, RequireNoAuth } from '../middlewares/HOC';
import PATHS from './PATHS';

import {
    LandginHome, LandingCerts, LandingCourses, LandingCertificationDetails, LandingCourseDetails, LandingStaff,
    Signup, Signin, ForgotPassword, ResetPassword, Profile, Activation,
    LandingContactUs, LandingWhyUs, TermsAndConditions, PrivacyPolicy, LandingHonor
} from '../pages';

const LandingStack = () => {
    return <Switch>
        {/* Basic Routes */}
        <Route path={PATHS.LANDING_HOME.URL} exact component={LandginHome} />
        <Route path={PATHS.LANDING_CERTIFICATIONS.URL} exact component={LandingCerts} />
        <Route path={PATHS.LANDING_COURSES.URL} exact component={LandingCourses} />
        <Route path={PATHS.LANDING_CERTIFICATIONS_DETAILS.URL} exact component={LandingCertificationDetails} />
        <Route path={PATHS.LANDING_COURSE_DETAILS.URL} exact component={LandingCourseDetails} />
        <Route path={PATHS.LANDING_WHYUS.URL} exact component={LandingWhyUs} />
        <Route path={PATHS.LANDING_STAFF.URL} exact component={LandingStaff} />
        <Route path={PATHS.LANDING_CONTACTUS.URL} exact component={LandingContactUs} />
        <Route path={PATHS.LANDING_TERMS_AND_CONDITIONS.URL} exact component={TermsAndConditions} />
        <Route path={PATHS.LANDING_PRIVACY_POLICY.URL} exact component={PrivacyPolicy} />
        <Route path={PATHS.LANDING_HONOR.URL} exact component={LandingHonor} />


        <Route path={PATHS.LANDING_RESET_PASSWORD.URL} exact component={ResetPassword} />
        <Route path={PATHS.LANDING_ACTIVATION.URL} exact component={Activation} />
        <Route path={PATHS.LANDING_SIGNUP.URL} exact component={RequireNoAuth(Signup)} />
        <Route path={PATHS.LANDING_SIGNIN.URL} exact component={RequireNoAuth(Signin)} />
        <Route path={PATHS.LANDING_FORGOT_PASSWORD.URL} exact component={RequireNoAuth(ForgotPassword)} />
        <Route path={PATHS.LANDING_PROFILE.URL} exact component={RequireAuth(Profile)} />
        <Route path={PATHS.USER_FREQ_EXAM.URL} exact component={Signin} />

        {/* Invalid Routes */}
        <Route>
            <Route path="*" exact component={LandginHome} />
            <Redirect from='*' to={PATHS.LANDING_HOME.URL} />
        </Route>

    </Switch>;
};

export default React.memo(LandingStack);
