import {
    USER_COURSES_INFO_CHANGED,
    USER_COURSES_OPERATION_START,
    USER_COURSES_OPERATION_FINISH,
    USER_COURSES_OPERATION_FAIL,
    USER_GET_ALL_COURSES,
    USER_CLEAR_COURSES_DATA,
    USER_SELECT_COURSE,
    USER_GET_COURSE_STRUCT_START,
    USER_GET_COURSE_STRUCT,
    USER_GET_COURSE_STRUCT_FAIL,
    USER_GET_COURSE_STRUCT_FINISH
} from '../../actions/types';

const INITIAL_STATE = {
    courses: [],
    error: '',
    loading: false,
    selectedCourse: null,
    selectedCourseStruct: [],
    selectedCourseStructLoading: false,
    selectedCourseStructError: ''
};


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_COURSES_INFO_CHANGED:
            return { ...state, [action.payload.props]: action.payload.value };
        case USER_COURSES_OPERATION_START:
            return { ...state, loading: true };
        case USER_COURSES_OPERATION_FINISH:
            return { ...state, loading: false };
        case USER_COURSES_OPERATION_FAIL:
            return { ...state, error: { [action.payload.props]: action.payload.value } };
        case USER_GET_ALL_COURSES:
            return { ...state, courses: action.payload };
        case USER_SELECT_COURSE:
            return { ...state, selectedCourse: action.payload, selectedCourseStruct: [] };
        case USER_GET_COURSE_STRUCT_START:
            return { ...state, selectedCourseStructLoading: true };
        case USER_GET_COURSE_STRUCT_FINISH:
            return { ...state, selectedCourseStructLoading: false };
        case USER_GET_COURSE_STRUCT_FAIL:
            return { ...state, selectedCourseStructError: { [action.payload.props]: action.payload.value } };
        case USER_GET_COURSE_STRUCT:
            return { ...state, selectedCourseStruct: action.payload };
        case USER_CLEAR_COURSES_DATA:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};

