import React, { useState } from 'react';
import { Button, Divider, Grid, GridColumn, Header, Icon, Menu, Modal, Segment } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import Stopwatch from '../../Timers/Stopwatch';
import Countdown from '../../Timers/Countdown';
import { Consts, Styles, Theme } from '../../../../res';
import { Separator } from '../../..';

const { noExtraSpaces, solidBtn, f_20_700_text, f_10_400_text } = Styles;

const ExamHeader = (props) => {
    const { t, Q, onGoingExam, userOnFinishExam, examFinishingLoading, isMockExam, theme } = props;
    const mockPartNumber = isMockExam ? parseInt(onGoingExam.examType[onGoingExam.examType.length - 1]) : -1;
    const [showFinishConfirm, setShowFinishConfirm] = useState(false);

    const renderTestLetsHeader = () => {
        return (<Menu borderless style={{ ...noExtraSpaces, backgroundColor: "#043a4e", borderRadius: 0 }}>
            <Menu.Item style={{ color: Theme.colors.WHITE, fontSize: f_10_400_text }}>
                {t("AUDITINGANDATTESTATION")}
            </Menu.Item>

            <Menu.Item active={mockPartNumber === 1} style={{ color: Theme.colors.WHITE, fontSize: f_10_400_text, borderLeft: "solid 2px #d5440b" }}>
                <Icon name={mockPartNumber === 1 ? 'check' : 'lock'} />
                {t('TestLet', { letNumber: 1 })}
            </Menu.Item>
            <Menu.Item active={mockPartNumber === 2} style={{ color: Theme.colors.WHITE, fontSize: f_10_400_text, borderLeft: "solid 2px #d5440b" }}>
                <Icon name={mockPartNumber === 2 ? 'check' : 'lock'} />
                {t('TestLet', { letNumber: 2 })}
            </Menu.Item>
            <Menu.Item active={mockPartNumber === 3} style={{ color: Theme.colors.WHITE, fontSize: f_10_400_text, borderLeft: "solid 2px #d5440b" }}>
                <Icon name={mockPartNumber === 3 ? 'check' : 'lock'} />
                {t('TestLet', { letNumber: 3 })}
            </Menu.Item>
            <Menu.Item active={mockPartNumber === 4} style={{ color: Theme.colors.WHITE, fontSize: f_10_400_text, borderLeft: "solid 2px #d5440b" }}>
                <Icon name={mockPartNumber === 4 ? 'check' : 'lock'} />
                {t('TestLet', { letNumber: 4 })}
            </Menu.Item>
            <Menu.Item active={mockPartNumber === 5} style={{ color: Theme.colors.WHITE, fontSize: f_10_400_text, borderLeft: "solid 2px #d5440b" }}>
                <Icon name={mockPartNumber === 5 ? 'check' : 'lock'} />
                {t('TestLet', { letNumber: 5 })}
            </Menu.Item>
        </Menu>
        );
    };
    const renderTimer = () => {

        if (onGoingExam.isViewOnly)
            return <Header as='h2' color='red' textAlign='center'>{t('ViewOnly')}</Header>;

        return (
            <>
                {isMockExam ?
                    <Countdown
                        expiryTimestamp={new Date().getTime() + (new Date(onGoingExam.finishDateTime) - new Date())}
                        onExpire={() => userOnFinishExam(onGoingExam)}
                        theme={theme}
                    />
                    : <Stopwatch
                        offsetTimestamp={new Date().getTime() + (new Date().getTime() - new Date(onGoingExam.startDateTime).getTime())}
                        theme={theme}
                    />}
                <Separator h='1vh' />
            </>
        );
    };
    const renderPrimaryHeader = () => {
        return (
            <Segment basic style={{ ...noExtraSpaces, paddingInline: 20, paddingTop: 6, borderBottom: `solid 1px ${Theme.colors.CPA_ORANGE}` }} >
                <Grid columns='equal' style={noExtraSpaces}>
                    <GridColumn style={noExtraSpaces}>
                        {renderTimer()}
                    </GridColumn>

                    <GridColumn style={noExtraSpaces}>
                    </GridColumn>

                    <GridColumn style={noExtraSpaces} textAlign='right' >
                        <Button
                            style={{ borderRadius: 0, backgroundColor: Theme.colors.CPA_ORANGE, color: Theme.colors.WHITE }}
                            content={t(onGoingExam.isViewOnly ? 'Exit' : 'SubmitTestLet').toUpperCase()}
                            loading={examFinishingLoading}
                            onClick={() => onGoingExam.isViewOnly ? userOnFinishExam(onGoingExam) : setShowFinishConfirm(true)}
                        />

                        <Modal
                            size='tiny'
                            open={showFinishConfirm}
                            content={<Segment basic>
                                <Header style={{ ...f_20_700_text, color: Theme.colors.PRIMARY_50 }}>{onGoingExam.examType === Consts.EXAM_TYPES.MOCK_PART1 ? t("AreYouSureFinishSectionExam") : t('AreYouSureFinishExam')}</Header>
                                <Divider hidden />
                                <Button.Group fluid style={{ gap: 10 }}>
                                    <Button
                                        style={{ ...solidBtn, borderRadius: 6, backgroundColor: Theme.colors.ERROR_25, color: Theme.colors.ERROR_75 }}
                                        onClick={() => {
                                            userOnFinishExam(onGoingExam);
                                            setShowFinishConfirm(false);
                                        }}
                                    >
                                        {t('ExitExam')}
                                    </Button>
                                    <Button
                                        style={{ ...solidBtn, borderRadius: 6 }}
                                        onClick={() => setShowFinishConfirm(false)}
                                    >
                                        {t('Continue')}
                                    </Button>
                                </Button.Group>
                            </Segment>}
                        />

                    </GridColumn>
                </Grid>
            </Segment>
        );
    };


    if (!Q) return null;

    return (
        <Segment basic style={noExtraSpaces}>
            {isMockExam && renderTestLetsHeader()}
            {renderPrimaryHeader()}
        </Segment>
    );
};

export default withTranslation()(ExamHeader);