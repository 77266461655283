import React, { useEffect } from 'react';
import { Segment, Card, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EmptySegment from '../Operantinals/EmptySegment';
import { setNotificationsSeen } from '../../actions';
import request from '../../api';
import { checkNotificationLink } from '../../helpers/checkNotificationLink';
import { Consts } from '../../res';
import Logo from '../../res/images/logo.svg';
import emptyImage from '../../res/images/walls/empty2.svg';


const NotificationsList = ({ items, loading, setNotificationsSeen, type }) => {
    const { t } = useTranslation();

    useEffect(() => {
        onNotificationSeen();
        //eslint-disable-next-line
    }, []);

    const onNotificationSeen = async () => {
        try {
            const seenNots = items.filter(n => n.type === type && !n.readAt).map(n => n.notificationId);
            if (seenNots.length) {
                await request.post('/user/notifications/read', { notificationsIds: seenNots });
                if (type === Consts.NOTIFICATIONS_TYPES.NORMAL) setNotificationsSeen(seenNots);
            }
        } catch (err) {
            return err;
        }
    };

    if (loading) return <Segment basic loading={loading} padded />;
    if (!items.length) return <EmptySegment image={emptyImage} text={t('NotificationsAppearHere')} />;

    return (
        <Segment basic style={type === Consts.NOTIFICATIONS_TYPES.IMPORTANT ? { maxHeight: '75vh', overflow: 'auto', maxWidth: '30vw' } : {}}>
            <Card.Group itemsPerRow={1} >
                {
                    items.map((i, indx) => <Card key={indx} link as={Link} to={checkNotificationLink(i)} style={{ margin: 5, backgroundColor: i.readAt ? '#fff' : '#eee' }} >
                        {i.type === Consts.NOTIFICATIONS_TYPES.IMPORTANT && <Image src={i.imgUrl || Logo} style={{ display: 'flex', height: 200, width: null }} />}
                        <Card.Content>
                            {i.type === Consts.NOTIFICATIONS_TYPES.NORMAL && <Image floated='left' size='tiny' src={i.imgUrl || Logo} />}
                            <Card.Header>{i.title}</Card.Header>
                            <Card.Meta>{new Date(i.creatAt).toLocaleString()}</Card.Meta>
                            <Card.Description>{i.content}</Card.Description>
                        </Card.Content>
                    </Card>)
                }
            </Card.Group>
        </Segment>
    );
};

const mapStateToProps = ({ notifications }) => {
    const { loading } = notifications;
    return { loading };
};

export default connect(mapStateToProps, { setNotificationsSeen })(NotificationsList);;