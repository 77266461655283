import i18n from 'i18next';
import PATHS from '../Routers/PATHS';
import { Consts } from '../res';

export const getHeaderBarData = () => {
    if (Consts.HIDE_MODE.MENUS) {
        return [
            { id: 1, name: i18n.t('Home'), link: PATHS.LANDING_HOME.URL },
            // { name: i18n.t('WhyUs'), link: '/whyus' },
            // { name: i18n.t('Staff'), link: '/staff' },
            { id: 6, name: i18n.t('AboutUs'), link: PATHS.LANDING_CONTACTUS.URL },
            { id: 7, name: i18n.t('HonorRoll'), link: PATHS.LANDING_HONOR.URL },
        ];    
    }
    return [
        { id: 1, name: i18n.t('Home'), link: PATHS.LANDING_HOME.URL },
        { id: 2, name: i18n.t('CertificatesCurriculums'), link: PATHS.LANDING_CERTIFICATIONS.URL },
        { id: 3, name: i18n.t('Courses'), link: PATHS.LANDING_COURSES.URL },
        // { name: i18n.t('WhyUs'), link: '/whyus' },
        // { name: i18n.t('Staff'), link: '/staff' },
        { id: 6, name: i18n.t('AboutUs'), link: PATHS.LANDING_CONTACTUS.URL },
        { id: 7, name: i18n.t('HonorRoll'), link: PATHS.LANDING_HONOR.URL },
    ];
};