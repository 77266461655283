import React, { useState, useEffect } from 'react';
import { Router as PlainRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import i18n from 'i18next';
import cookies from 'react-cookies';
import Layouts from './layouts';
import history from './history';
import { checkAuth, getAllCountries, saveNotificationToken } from './actions';
import onLangChange from './helpers/checkLang';
import { firebaseMessagingInit } from './NotificationsManager';


const Main = (props) => {
    const cookiesLang = cookies.load('languagePref');
    const { user, checkAuth, getAllCountries, saveNotificationToken } = props;
    const [lang, setLang] = useState(cookiesLang || i18n.language);
    // const divRef = createRef();
    // const [showSidebar, setShowSidebar] = useState(false);

    // const onToggleSidebar = (value) => {
    //     setShowSidebar(value);
    // };

    useEffect(() => {
        checkAuth();
        getAllCountries();
        onLangChange(lang, setLang);
        firebaseMessagingInit(saveNotificationToken);
        // eslint-disable-next-line
    }, []);

    return (
        <PlainRouter history={history}>
            <Layouts user={user} />
        </PlainRouter>
        /* <SideBarNav toggle={onToggleSidebar} showSidebar={showSidebar} user={user}>
            <div ref={divRef}>
                <Suspense fallback={<Spinner />}>
                    <Sticky context={divRef}>
                            <NavBar lang={lang} setLang={setLang} />
                        </Sticky>

                    <Footer />


                </Suspense>
            </div>
        </SideBarNav>

        <ScrollToTop />
        <WhastsappButton showSidebar={showSidebar} />
        <MessagesButton user={user} showSidebar={showSidebar} />
        <PanelButton lang={lang} toggle={onToggleSidebar} showSidebar={showSidebar} user={user} /> */

    );
};

const mapStateTopProps = ({ auth }) => {
    const { user } = auth;
    return { user };
};
export default connect(mapStateTopProps, { checkAuth, getAllCountries, saveNotificationToken })(Main);