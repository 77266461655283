import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Segment, Table, Checkbox } from 'semantic-ui-react';
import { Consts } from '../../../../res';

const McqsQuestionTemplate = ({ Q, onAnswerQuestion, t, theme }) => {

    const studentAnswer = Q.questionTypeId === Consts.QUESTIONS_TYPES.MCQS ? Q.answerOptionId : Q.answerText;
    const alphArray = [t('A'), t('B'), t('C'), t('D')];

    return (
        <Segment basic style={{ minHeight: '40vh', overflow: 'auto', fontSize: '14px' }}>
            <Table basic='very' unstackable definition collapsing>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell style={{ border: 'none' }} />
                        <Table.HeaderCell style={{ border: 'none' }} />
                        {
                            Q.options[0].contents.length > 1 &&
                            Q.options[0].contents.map(col => <Table.HeaderCell key={col.columnId} style={{ border: 'none' }}>{col.colName.trim()}</Table.HeaderCell>)
                        }
                    </Table.Row>
                </Table.Header>

                <Table.Body>

                    {
                        Q.options.map((o, indx) => <Table.Row
                            key={o.id}
                        // negative={showAnswers && o.id !== correctAnswerOptionId}
                        // positive={showAnswers && o.id === correctAnswerOptionId}
                        >
                            <Table.Cell
                                style={{ cursor: 'pointer', backgroundColor: 'white', border: 'none' }}
                                onClick={() => onAnswerQuestion({ ...Q, answerOptionId: o.id })}
                                collapsing
                            >
                                {/* <Checkbox
                                    checked={studentAnswer === o.id}
                                // onChange={() => onAnswerQuestion({ ...Q, isFlagged: false, answerOptionId: o.id })}
                                /> */}
                                {theme !== Consts.EXAM_THEMES.CIA ?
                                    <Button
                                        circular
                                        icon='circle outline'
                                        style={{ color: studentAnswer === o.id ? 'white' : 'black', backgroundColor: studentAnswer === o.id ? 'green' : 'transparent' }}
                                    /> :
                                    <Checkbox
                                        radio
                                        checked={studentAnswer === o.id}
                                        style={{ transform: 'scale(1.5)' }}
                                    />}
                            </Table.Cell>

                            <Table.Cell style={{ border: 'none' }} collapsing>
                                {alphArray[indx]}.
                            </Table.Cell>

                            {
                                o.contents.map(col => <Table.Cell key={`${o.id}${col.columnId}`} style={{ border: 'none' }}>
                                    {col.content.trim()}
                                </Table.Cell>
                                )
                            }
                        </Table.Row>)
                    }

                </Table.Body>
            </Table>
        </Segment>
    );
};

export default withTranslation()(McqsQuestionTemplate);