import React, { useState } from 'react';
import { Button, Container, Divider, Grid, GridColumn, Header, Modal, Progress, Segment } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import Stopwatch from '../../Timers/Stopwatch';
import Countdown from '../../Timers/Countdown';
import { Consts, Styles, Theme } from '../../../../res';
import { Separator } from '../../..';
import { getExamTypeString } from '../../../../helpers/examHelpers';
import request from '../../../../api';

const { noExtraSpaces, solidBtn, f_20_700_text } = Styles;

const ExamHeader = (props) => {
    const { t, course, courseStruct, user,
        Q, Qindex, questions,
        onGoingExam, userOnFinishExam, examFinishingLoading, isMockExam, summary, theme } = props;

    const solved = questions.reduce((acc, q) => !!(q.questionTypeId === Consts.QUESTIONS_TYPES.MCQS ? q.answerOptionId : q.answerText) + acc, 0) || 0;
    const [showFinishConfirm, setShowFinishConfirm] = useState(false);

    const getQUnitTopic = () => {
        let unitName = '', topicName = '';
        for (let i = 0; i < courseStruct.length; i++) {
            for (let j = 0; j < courseStruct[i].topics.length; j++) {
                if (courseStruct[i].topics[j].id === Q.topicId) {
                    topicName = `${courseStruct[i].topics[j].name} (${courseStruct[i].topics[j].rank})`;
                    unitName = `${courseStruct[i].name} (${courseStruct[i].rank})`;
                    return { unitName, topicName };
                }
            }
        }
        return { unitName, topicName };
    };

    const onPauseTimer = (isPause) => {
        const examRoundId = onGoingExam.examRoundId;

        request.post('/student/round/exam/pause', { examRoundId, isPause });
    };

    const renderTimer = () => {

        if (onGoingExam.isViewOnly)
            return <Header as='h2' color='red' textAlign='center'>{t('ViewOnly')}</Header>;

        return (
            <>
                {isMockExam ?
                    <Countdown
                        expiryTimestamp={new Date().getTime() + (new Date(onGoingExam.finishDateTime) - new Date())}
                        onExpire={() => userOnFinishExam(onGoingExam)}
                        theme={theme}
                    />
                    : <Stopwatch
                        offsetTimestamp={new Date().getTime() + (new Date().getTime()
                            - new Date(onGoingExam.startDateTime).getTime())
                            - (onGoingExam.totalPauseSeconds * 1000)
                            - (onGoingExam.lastPauseTime ? (new Date().getTime() - new Date(onGoingExam.lastPauseTime).getTime()) : 0)
                        }
                        theme={theme}
                        onPauseChanged={onPauseTimer}
                        isAutoStart={!onGoingExam.lastPauseTime}
                    />}
                <Separator h='1vh' />
            </>
        );
    };
    const renderPrimaryHeader = () => {
        return (
            <Segment basic style={{ margin: 0, padding: 0, paddingTop: 20, paddingBottom: 5, backgroundColor: '#4c4c4c' }} inverted >
                <Container style={{ width: '95vw' }}>
                    <Grid columns='4' textAlign='center' style={noExtraSpaces} stackable>

                        <GridColumn style={noExtraSpaces} width={6}>
                            <Header inverted as='h5' style={noExtraSpaces}>{`${course.certificateName} (${course.certificateCode})`}</Header>
                            <Header inverted as='h5' style={noExtraSpaces}>{course.name}</Header>
                        </GridColumn>

                        <GridColumn style={noExtraSpaces} width={4}>
                            {renderTimer()}
                        </GridColumn>

                        <GridColumn style={noExtraSpaces} width={3}>
                            {isMockExam && <Progress label={t('Progress')} precision={1} progress='percent' total={questions.length} value={solved} color='green' size='small' inverted style={{ backgroundColor: 'wheat', width: '50%', transform: `translateX(${i18next.language === 'ar' ? -50 : 50}%)` }} />}
                        </GridColumn>

                        <GridColumn style={noExtraSpaces} width={3}>
                            <Button
                                negative
                                fluid
                                content={t(onGoingExam.isViewOnly ? 'Exit' : isMockExam ? 'FinishSection' : 'Finish').toUpperCase()}
                                loading={examFinishingLoading}
                                style={{ backgroundColor: 'wheat', color: 'black' }}
                                onClick={() => onGoingExam.isViewOnly ? userOnFinishExam(onGoingExam) : setShowFinishConfirm(true)}
                            />

                            <Modal
                                size='tiny'
                                open={showFinishConfirm}
                                content={<Segment basic>
                                    <Header style={{ ...f_20_700_text, color: Theme.colors.PRIMARY_50 }}>{onGoingExam.examType === Consts.EXAM_TYPES.MOCK_PART1 ? t("AreYouSureFinishSectionExam") : t('AreYouSureFinishExam')}</Header>
                                    <Divider hidden />
                                    <Button.Group fluid style={{ gap: 10 }}>
                                        <Button
                                            style={{ ...solidBtn, borderRadius: 6, backgroundColor: Theme.colors.ERROR_25, color: Theme.colors.ERROR_75 }}
                                            onClick={() => {
                                                userOnFinishExam(onGoingExam);
                                                setShowFinishConfirm(false);
                                            }}
                                        >
                                            {t('ExitExam')}
                                        </Button>
                                        <Button
                                            style={{ ...solidBtn, borderRadius: 6 }}
                                            onClick={() => setShowFinishConfirm(false)}
                                        >
                                            {t('Continue')}
                                        </Button>
                                    </Button.Group>
                                </Segment>}
                            />

                        </GridColumn>
                    </Grid>
                </Container>
            </Segment>
        );
    };

    const renderSecondaryHeader = () => {
        const { unitName, topicName } = getQUnitTopic();
        return (
            <Segment basic style={{ margin: 0, padding: 5, backgroundColor: '#639FD2' }} inverted>
                <Container style={{ width: '95vw' }}>
                    <Grid columns='equal' textAlign='center' style={noExtraSpaces} stackable>
                        <GridColumn style={noExtraSpaces}>
                            <Header inverted as='h5' style={noExtraSpaces}>{`${t('Name')}: ${user.firstName} ${user.lastName}`}</Header>
                        </GridColumn>

                        <GridColumn style={noExtraSpaces}>
                            {!isMockExam && <Header inverted as='h5' style={noExtraSpaces}>{unitName}</Header>}
                            {!isMockExam && <Header inverted as='h5' style={noExtraSpaces}>{topicName}</Header>}
                            {isMockExam && <Header inverted as='h5' style={noExtraSpaces}>{getExamTypeString(onGoingExam.examType, onGoingExam.orderNum)}</Header>}
                        </GridColumn>

                        <GridColumn style={noExtraSpaces}>
                            {!isMockExam && <Header inverted as='h5' style={noExtraSpaces}>{getExamTypeString(onGoingExam.examType, onGoingExam.orderNum)}</Header>}
                            {
                                summary.show ?
                                    <Header inverted as='h5' style={noExtraSpaces}>{t('ReviewAnswers')}</Header>
                                    : <Header inverted as='h5' style={noExtraSpaces}>{`${t('Question')}: ${Qindex + 1} / ${questions.length}`}</Header>
                            }
                        </GridColumn>
                    </Grid>
                </Container>
            </Segment>
        );
    };

    if (!Q) return null;

    return (
        <Segment basic style={noExtraSpaces}>
            {renderPrimaryHeader()}
            {renderSecondaryHeader()}
        </Segment>
    );
};

export default withTranslation()(ExamHeader);