import _ from 'lodash';
import React, { createRef, useEffect, useState } from 'react';
import { Grid, Segment, Modal, Label, GridColumn, TextArea, Card, GridRow, Form, Header, Transition, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { userVideoComments } from '../../Data Stores';
import request from '../../api';
import { ReportForm } from '..';
import { Consts, Theme, Styles } from '../../res';
import '../../res/css/video-editor.css';
import { toHHMMSS } from '../../helpers/toHHMMSS';

let lectureRoundId;
let progress = 0;
const { solidBtn, noExtraSpaces, f_16_700_text } = Styles;

const VideoPlayer = (props) => {
    const { onClose, videoDetails: { url, videoId, lastLikeType, likesCnt, disLikesCnt, viewsCnt, userVideoLastPercent = 0, showReactions = true }, kind, topicId, user, onCourseProgress } = props;
    const { t } = useTranslation();
    const [fullScreen, setFullScreen] = useState(false/*!isIOS()*/);
    const [showReport, setShowReport] = useState(false);
    const [likes, setLikes] = useState(likesCnt || 0);
    const [dislikes, setDislikes] = useState(disLikesCnt || 0);
    const [views] = useState(viewsCnt || 0);
    const videoRef = createRef();
    const [comments, setComments] = useState([]);
    const [commentsToShow, setCommentsToShow] = useState([]);
    const [segments, setSegments] = useState([]);
    const [showSegments, setShowSegment] = useState(false);
    const [newComment, setNewComment] = useState('');
    const [canResume, setCanResume] = useState(true);
    const [videoStarted, setVideoStarted] = useState(false);

    const getAllComments = async () => {
        try {
            const data = await userVideoComments(videoId).load();
            setComments(data);
        } catch (err) {

        }
    };

    const getAllSegmetns = async () => {
        try {
            const { data: { data } } = await request.get(`/student/video/${videoId}/segments`);
            setSegments(data);
        } catch (err) {

        }
    };

    const REACTIONS = {
        LIKE: "LIKE",
        UNLIKE: "UNLIKE",
        DISLIKE: "DISLIKE",
        UNDISLIKE: "UNDISLIKE"
    };
    const [reaction, setReaction] = useState(lastLikeType === REACTIONS.LIKE ? REACTIONS.LIKE : lastLikeType === REACTIONS.DISLIKE ? REACTIONS.DISLIKE : null || null);

    const { isAdmin } = user;

    useEffect(() => {
        getAllComments();
        getAllSegmetns();
        
        // if (!isIOS()) {
        //     fullScreening('expand-vid-cont');
        // }

        return () => {
            if (topicId && lectureRoundId && progress < 100 && progress >= 1)
                onVideoClosed();
        };
        //eslint-disable-next-line
    }, []);

    const onVideoStart = async () => {
        setVideoStarted(true);
        if (!topicId)
            return;
        try {
            const { data: { lectureRoundId: roundId } } = await request.post('/student/round/lecture/start', { videoId, topicId });
            lectureRoundId = roundId;
        } catch (err) {
            return err;
        }
    };

    const onDuration = async (data) => {
        if (canResume) {
            if (userVideoLastPercent !== 0)
                videoRef.current.seekTo(userVideoLastPercent / 100, 'fraction');
            setCanResume(false);
        }
    };

    const onVideoEnd = async () => {
        if (!topicId) return;
        try {
            await request.post('/student/round/lecture/finish', { lectureRoundId });
            onCourseProgress(true);
        } catch (err) {
            return err;
        }
    };


    const onVideoClosed = async () => {
        if (!topicId) return;
        try {
            await request.post('/student/round/lecture/progress', { lectureRoundId, progressPercent: progress });
            onCourseProgress(true);
        } catch (err) {
            return err;
        }
    };

    const reactOnVideo = async (type) => {
        try {
            let t;
            if (!reaction) {
                if (type === REACTIONS.LIKE) setLikes(p => p + 1);
                else if (type === REACTIONS.DISLIKE) setDislikes(p => p + 1);
                setReaction(type);
                t = type;

            } else {
                if (type === REACTIONS.LIKE && reaction === REACTIONS.LIKE) {
                    setLikes(p => p - 1);
                    setReaction(null);
                    t = REACTIONS.UNLIKE;
                }
                else if (type === REACTIONS.LIKE && reaction === REACTIONS.DISLIKE) {
                    setLikes(p => p + 1);
                    setDislikes(p => p - 1);
                    setReaction(type);
                    t = type;
                } else if (type === REACTIONS.DISLIKE && reaction === REACTIONS.LIKE) {
                    setLikes(p => p - 1);
                    setDislikes(p => p + 1);
                    setReaction(type);
                    t = type;
                }
                else if (type === REACTIONS.DISLIKE && reaction === REACTIONS.DISLIKE) {
                    setDislikes(p => p - 1);
                    setReaction(null);
                    t = REACTIONS.UNDISLIKE;
                }
            }
            await request.post('/student/reaction', { type: t, videoId });
        } catch (err) {
            setReaction(previousType => previousType);
        }
    };

    const fullScreening = (expadElementId) => {

        function exitHandler() {
            if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                setFullScreen(false);
            }
        }
        if (document.addEventListener) {
            document.addEventListener('webkitfullscreenchange', exitHandler, false);
            document.addEventListener('mozfullscreenchange', exitHandler, false);
            document.addEventListener('fullscreenchange', exitHandler, false);
            document.addEventListener('MSFullscreenChange', exitHandler, false);
        }

        if (!document.fullscreenElement) {
            const element = document.getElementById(expadElementId);
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
            } else {
                element.webkitEnterFullscreen();
            }
            setFullScreen(true);
        }
        else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
            setFullScreen(false);
        }
    };

    const seekVideo = (sec) => {
        const currentTime = videoRef.current.getCurrentTime();
        if (currentTime) {
            videoRef.current.seekTo(currentTime + sec, 'seconds');
        }
    };

    const renderVideoStats = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundColor: Theme.colors.PRIMARY_50, alignItems: 'center' }}>
                <div style={{ display: 'flex' }}>
                    <Label.Group circular size='large' style={{ backgroundColor: Theme.colors.PRIMARY_50 }}>
                        <Label basic style={{ ...noExtraSpaces, backgroundColor: Theme.colors.PRIMARY_50, color: Theme.colors.WHITE, border: 'none' }} content={`${views} ${t('View')}`} />
                        <Label basic style={{ ...noExtraSpaces, backgroundColor: Theme.colors.PRIMARY_50, color: Theme.colors.WHITE, border: 'none' }} content={`${likes} ${t('Like')}`} />
                        <Label basic style={{ ...noExtraSpaces, backgroundColor: Theme.colors.PRIMARY_50, color: Theme.colors.WHITE, border: 'none' }} content={`${dislikes} ${t('Dislike')}`} />
                    </Label.Group>
                </div>

                <div style={{ display: 'flex', marginRight: 5 }}>
                    <Label as="a" basic style={{ ...noExtraSpaces, backgroundColor: Theme.colors.PRIMARY_50, color: Theme.colors.WHITE, border: 'none' }} icon='times' onClick={onClose} />
                </div>
            </div>
        );
    };
    const renderNotesForm = () => {
        return (
            <Segment basic style={{ backgroundColor: Theme.colors.GREY_10 }}>
                <Segment basic>
                    <Form>
                        <Form.Field>
                            <TextArea placeholde={t('Note')} style={{ maxHeight: '12vh' }} value={newComment} onChange={(e, { value }) => setNewComment(value)} />
                        </Form.Field>
                        <Form.Button fluid style={solidBtn} onClick={() => {
                            const c = { text: newComment, second: videoRef?.current?.getCurrentTime() };

                            if (c.second != null) {
                                userVideoComments(videoId).insert(c);
                                setNewComment('');
                                setComments([...comments, c]);
                            }
                        }}>{t('AddNote')}</Form.Button>
                    </Form>
                </Segment>

                <Segment basic style={{ overflow: 'auto', maxHeight: '40vh' }}>
                    {
                        _.orderBy(commentsToShow, ['second'], 'desc').map((c, index) => <Card
                            key={index}
                            style={{ backgroundColor: Theme.colors.PRIMARY_05, borderRadius: 8, padding: 10 }}
                            description={c.text}
                        />)
                    }
                </Segment>
            </Segment>
        );
    };
    const renderVideoPane = () => {
        return <Segment id="full-vid-cont" basic inverted style={{ ...noExtraSpaces, position: 'relative' }}>
            <ReactPlayer
                light
                ref={videoRef}
                controls
                url={url} //'https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4'
                width='100%'
                height='100%'
                pip={false}
                playing
                onContextMenu={e => e.preventDefault()}
                config={{
                    file: {
                        // forceHLS: true,
                        hlsOptions: {
                            xhrSetup: function (xhr) {
                                xhr.withCredentials = true; // send cookies
                            },
                            debug: false
                        },
                        attributes: {
                            controlsList: 'nodownload nofullscreen',
                            disablePictureInPicture: true
                        }
                    }
                }}
                onStart={() => onVideoStart()}
                onEnded={() => onVideoEnd()}
                onDuration={() => onDuration()}
                onProgress={({ played, playedSeconds }) => {
                    progress = parseInt(played * 100);
                    setCommentsToShow(comments.filter(c => c.second <= playedSeconds));
                }}
            />


            {
                videoStarted &&
                <div className={`not-selectable video-overlay ${fullScreen ? 'fullscreen-overlay' : ''}`}>
                    {user && <span style={{ zIndex: 10, fontSize: '18px' }}>{`${user.email} [${user.contactNumber}]`}</span>}
                    {user && <span style={{ zIndex: 10, fontSize: '18px' }}>{`${user.firstName} ${user.lastName} (#${user.id})`}</span>}
                </div>
            }

            <Transition.Group animation='slide right' duration='300'>
                {
                    showSegments &&
                    <div style={{ padding: 10, position: 'absolute', inset: 0, right: '70%', overflow: 'auto', zIndex: showSegments ? 20 : -1, backgroundColor: 'rgba(0,0,0,0.8)' }}>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div> <Header style={{ ...f_16_700_text, color: 'white' }}>{t("Chapters")}</Header></div>
                            <div><Icon link name='close' onClick={() => setShowSegment(false)} /></div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', gap: '5', marginTop: 20 }}>
                            {
                                segments.map((seg, i) => <div key={i} onClick={() => {
                                    seekVideo(seg.fromSecond);
                                    setShowSegment(false);
                                }}
                                    style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', gap: 5, justifyContent: 'space-between', alignItems: 'center', borderBottom: 'solid white' }}
                                >
                                    <p>{seg.title}</p>
                                    <p>{toHHMMSS(seg.fromSecond)}</p>
                                </div>)
                            }
                        </div>
                    </div>
                }
            </Transition.Group>


        </Segment>;
    };
    const renderControls = () => {
        if (!isAdmin && showReactions) return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
                <div style={{ display: 'flex' }}>
                    <Label.Group circular size='large'>
                        <Label as='a' basic color='blue' style={{ ...noExtraSpaces, border: 'none' }} content={t('Like')} icon={reaction === REACTIONS.LIKE ? "thumbs up" : "thumbs up outline"} onClick={() => reactOnVideo(REACTIONS.LIKE)} />
                        <Label as='a' basic color='grey' style={{ ...noExtraSpaces, border: 'none' }} content={t('Dislike')} icon={reaction === REACTIONS.DISLIKE ? "thumbs down" : "thumbs down outline"} onClick={() => reactOnVideo(REACTIONS.DISLIKE)} />
                        {!fullScreen && <Label as='a' basic color='red' style={{ ...noExtraSpaces, border: 'none' }} content={t('ReportProblem')} icon='exclamation circle' onClick={() => setShowReport(true)} />}
                    </Label.Group>
                </div>

                <div style={{ display: 'flex' }}>
                    <Label.Group circular size='large'>
                        <Label as='a' basic color='black' style={{ ...noExtraSpaces, border: 'none' }} content='+15' icon='redo' onClick={() => seekVideo(15)} />
                        <Label as='a' basic color='black' style={{ ...noExtraSpaces, border: 'none' }} content='-15' icon='undo' onClick={() => seekVideo(-15)} />
                        <Label as='a' basic color='black' style={{ ...noExtraSpaces, border: 'none' }} content='E' icon={fullScreen ? 'compress' : 'expand arrows alternate'} onClick={() => fullScreening('expand-vid-cont')} />
                        <Label as='a' basic color='black' style={{ ...noExtraSpaces, border: 'none' }} content='F' icon={fullScreen ? 'compress' : 'expand'} onClick={() => fullScreening('full-vid-cont')} />
                        <Label as='a' basic color='black' style={{ ...noExtraSpaces, border: 'none' }} content='CC' icon='closed captioning outline' onClick={() => setShowSegment(true)} />
                    </Label.Group>
                </div>
            </div>
        );
    };

    return (
        <Segment id='expand-vid-cont' basic style={{ ...noExtraSpaces, backgroundColor: Theme.colors.WHITE }}>

            <Grid columns={2} stackable stretched style={noExtraSpaces}>
                <GridRow columns={2} style={noExtraSpaces}>
                    {renderVideoStats()}
                </GridRow>

                <GridRow style={{ ...noExtraSpaces }}>
                    <GridColumn width={4} style={noExtraSpaces}>
                        {renderNotesForm()}
                    </GridColumn>
                    <GridColumn width={12} style={noExtraSpaces}>
                        {renderVideoPane()}
                    </GridColumn>
                </GridRow>

                <GridRow columns={2} style={noExtraSpaces}>
                    {renderControls()}
                </GridRow>
            </Grid >

            <Modal
                onClose={() => setShowReport(false)}
                open={showReport}
                content={<ReportForm reportOn={Consts.REPORTS_TYPES.VIDEO} reportLink={{ ...props.videoDetails, videoKind: kind }} id={videoId} showModal={setShowReport} />}
                closeIcon
                header={t('ReportProblem')}
                size='small'
                centered
                closeOnDimmerClick={false}
            />
        </Segment >
    );
};

const mapStateToProps = ({ auth }) => {
    const { user } = auth;
    return { user };
};

export default connect(mapStateToProps, {})(VideoPlayer);