import React from 'react';
import { Grid, GridColumn, Image, Header } from 'semantic-ui-react';
import { Styles, Theme } from '../../res';
import placeholderImage from '../../res/images/course.jpg';

const { f_16_700_text, f_12_400_text } = Styles;

const NewsCard = ({ data }) => {
    const { title, content, imgUrl, extUrl } = data;

    return (
        <a href={extUrl || undefined} target='_blank' rel="noreferrer" >
            <div style={{ padding: 10, backgroundColor: Theme.colors.PRIMARY_2, borderRadius: 8, margin: 5 }}>
                <Grid columns={2} stretched style={{ padding: 10 }}>
                    <GridColumn width={6} style={{ padding: 5 }}>
                        <Image fluid src={imgUrl || placeholderImage} style={{ borderRadius: 8, objectFit: 'cover', minHeight: '20vh' }} />
                    </GridColumn>

                    <GridColumn width={10} textAlign='left' style={{ padding: 5 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Header style={f_16_700_text}>{title}</Header>
                            <Header.Subheader style={f_12_400_text}>{content}</Header.Subheader>
                        </div>
                    </GridColumn>
                </Grid>
            </div>
        </a>
    );
};

export default NewsCard;