import React, { useEffect, useState } from 'react';
import { Card, Grid, GridColumn, Header, Image, List } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { BuyingButton } from '..';
import request from '../../api';
import { Theme, Styles } from '../../res';

import packageImage from '../../res/images/package1.jpg';

const { f_16_700_text, f_12_400_text } = Styles;

const Package = ({ data }) => {
    const { t } = useTranslation();
    const { id, packageName, imgUrl, showOnly, validitySeconds } = data;
    const [features, setFeatures] = useState([]);
    const [selected, setSelected] = useState(false);


    useEffect(() => {
        const getFeaturesList = async () => {
            try {
                const { data: { features } } = await request.get(`/supscription/features/package/${id}`);
                setFeatures(features);
            } catch (error) {
                setFeatures([]);
            }
        };
        getFeaturesList();
    }, [id]);

    return (
        <Card

            style={{ height: '85vh', padding: 8, borderRadius: 8, boxShadow: selected ? '0px 8px 20px rgba(59, 45, 132, 0.64)' : 'none', backgroundColor: selected ? Theme.colors.PRIMARY_50 : Theme.colors.PRIMARY_05 }}
            onMouseEnter={() => setSelected(true)}
            onMouseLeave={() => setSelected(false)}
        >
            {!selected && <Image
                src={imgUrl || packageImage}
                style={{ display: 'flex', height: 150, width: null }}
                fluid
            />}

            <Card.Content style={{ border: 'none' }}>
                <Header style={{ ...f_16_700_text, color: selected ? Theme.colors.WHITE : Theme.colors.BLACK }}>{packageName.toUpperCase()}</Header>
                <Header.Subheader style={{ ...f_16_700_text, color: selected ? Theme.colors.WHITE : Theme.colors.BLACK }}>{`${validitySeconds / 60 / 60 / 24} ${t('Day')}`}</Header.Subheader>
            </Card.Content>


            <Card.Content style={{ border: 'none' }}>
                <List relaxed bulleted style={{ ...f_12_400_text, color: selected ? Theme.colors.WHITE : Theme.colors.BLACK }}>
                    {features.map((f, id) => <List.Item
                        key={id}
                        content={f.featureName}
                    />)}
                </List>
            </Card.Content>

            {!showOnly && <Card.Content extra>
                <Grid columns={2} verticalAlign='middle' stackable textAlign='center'>
                    <GridColumn>
                        <BuyingButton mode="PRICE_DISCOUNT_AS_TEXT" data={data} />
                    </GridColumn>
                    <GridColumn>
                        <BuyingButton mode="NO_PRICE_AS_BUTTON" selectedForHover={selected} data={data} />
                    </GridColumn>
                </Grid>
            </Card.Content>}
        </Card>
    );
};

export default Package;
