import React from 'react';
import { Image, Popup } from 'semantic-ui-react';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { Sidenav, Nav, Badge } from 'rsuite';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useProSidebar } from 'react-pro-sidebar';
import { Languager } from '../../components';
import { getSideBarData } from '../SidebarData';
import { signout } from '../../actions';
import { Consts, Theme } from '../../res';
import OffIcon from '@rsuite/icons/Off';
import GlobalIcon from '@rsuite/icons/Global';
import logo from '../../res/images/logoblue.svg';
import shortLogo from '../../res/images/logobluenotext.svg';
import NoticeIcon from '@rsuite/icons/Notice';
import MessageIcon from '@rsuite/icons/Message';
import PATHS from '../../Routers/PATHS';

const SideBarNav = (props) => {
    const { notNormalSeen, user, signout, openCloseSideBar, opened } = props;
    const { pathname } = useLocation();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const { broken } = useProSidebar();

    return (
        <Sidenav expanded={opened} style={{ minHeight: '100vh', backgroundColor: Theme.colors.PRIMARY_50 }}>
            <Sidenav.Header>
                <Image src={opened ? logo : shortLogo} size='small' centered />
            </Sidenav.Header>
            <Sidenav.Body style={{ backgroundColor: Theme.colors.PRIMARY_50 }}>
                <Nav style={{ backgroundColor: Theme.colors.PRIMARY_50, color: Theme.colors.WHITE }}>
                    {getSideBarData(user.isAdmin).map(item =>
                        <Nav.Item
                            style={{ color: Theme.colors.WHITE, backgroundColor: Theme.colors.PRIMARY_50 }}
                            key={item.id}
                            icon={item.icon}
                            as={Link}
                            to={item.link}
                            eventKey={item.name}
                            onClick={broken ? () => openCloseSideBar(false) : undefined}
                            active={matchPath(pathname, { path: item.link, exact: true })?.isExact}
                        >
                            {item.name}
                        </Nav.Item>
                    )}

                    { !Consts.HIDE_MODE.MENUS && <Nav.Item
                        style={{ color: Theme.colors.WHITE, backgroundColor: Theme.colors.PRIMARY_50 }}
                        icon={<NoticeIcon pulse={notNormalSeen !== 0} style={{ color: notNormalSeen !== 0 && Theme.colors.ERROR_75, transform: notNormalSeen !== 0 && 'rotate(20deg)' }} />}
                        as={Link}
                        to={PATHS.USER_NOTIFICATIONS.URL}
                        eventKey={t('Notifications')}
                        onClick={broken ? () => openCloseSideBar(false) : undefined}
                        active={matchPath(pathname, { path: PATHS.USER_NOTIFICATIONS.URL, exact: true })?.isExact}

                    >
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            {t('Notifications')}
                            {notNormalSeen !== 0 && <Badge content={notNormalSeen} />}
                        </div>
                    </Nav.Item>
                    }
                </Nav>
            </Sidenav.Body>

            <Sidenav.Toggle expanded={isArabic ? !opened : opened} onToggle={expanded => openCloseSideBar(expanded)} style={{ backgroundColor: Theme.colors.PRIMARY_50, color: Theme.colors.WHITE }} />
            <Nav style={{ backgroundColor: Theme.colors.PRIMARY_50, color: Theme.colors.WHITE }} pullRight>
                <Nav.Item
                    style={{ color: Theme.colors.WHITE, backgroundColor: Theme.colors.PRIMARY_50 }}
                    icon={<MessageIcon />}
                    onClick={() => window.open('https://wa.link/q0v3z5', '_blank')}
                >
                    {t('TalkViaWhatsapp')}
                </Nav.Item>
                <Popup
                    on='click'
                    flowing
                    hoverable
                    trigger={
                        <Nav.Item
                            style={{ color: Theme.colors.WHITE, backgroundColor: Theme.colors.PRIMARY_50 }}
                            icon={<GlobalIcon />}
                        >
                            {t('Language')}
                        </Nav.Item>
                    }
                >
                    <Languager isMenu />
                </Popup>
                <Nav.Item
                    style={{ color: Theme.colors.WHITE, backgroundColor: Theme.colors.PRIMARY_50 }}
                    icon={<OffIcon />}
                    onClick={signout}
                >
                    {t('Logout')}
                </Nav.Item>
            </Nav>
        </Sidenav >
    );
};

const mapStateToProps = ({ notifications }) => {
    const { notNormalSeen } = notifications;
    return { notNormalSeen };
};

export default connect(mapStateToProps, { signout })(SideBarNav);
