
const useJsonSectionParser = (jsonData) => {
    try {
        const data = JSON.parse(jsonData);
        if (data) return data;
        return [];
    } catch (error) {
        return [];
    }
};

export default useJsonSectionParser;