import React, { useState } from 'react';
import { HtmlEditor, Toolbar, MediaResizing, Item, TableContextMenu, TableResizing } from 'devextreme-react/ui/html-editor';

const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
const fontValues = ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'];
const headerValues = [false, 1, 2, 3, 4, 5];


const TextEditor = ({ data, isStudent, isReadOnly, isArabic, onBlur = () => { } }) => {
    const [value, setValue] = useState(`<div style="direction:${isArabic ? 'rtl' : 'ltr'}">${data.value || ''}<div>`);
    const onValueChanged = ({ value }) => {
        setValue(value);
        if (data.setValue) data.setValue(value);
    };

    const renderToolBar = () => {
        if (isReadOnly) return null;
        if (isStudent) return <Toolbar multiline>
            <Item name="undo" />
            <Item name="redo" />
            <Item name="separator" />
            <Item
                name="size"
                acceptedValues={sizeValues}
            />
            <Item
                name="font"
                acceptedValues={fontValues}
            />
            <Item
                name="header"
                acceptedValues={headerValues}
            />
            <Item name="separator" />
            <Item name="bold" />
            <Item name="italic" />
            <Item name="strike" />
            <Item name="underline" />
            <Item name="separator" />
            <Item name="alignLeft" />
            <Item name="alignCenter" />
            <Item name="alignRight" />
            <Item name="alignJustify" />
            <Item name="separator" />
            <Item name="orderedList" />
            <Item name="bulletList" />
            <Item name="separator" />
            <Item name="color" />
            <Item name="clear" />
            <Item name="separator" />
            <Item name="insertTable" />
            <Item name="deleteTable" />
            <Item name="insertHeaderRow" />
            <Item name="insertRowAbove" />
            <Item name="insertRowBelow" />
            <Item name="separator" />
            <Item name="deleteRow" />
            <Item name="insertColumnLeft" />
            <Item name="insertColumnRight" />
            <Item name="deleteColumn" />
            <Item name="separator" />
            <Item name="cellProperties" />
            <Item name="tableProperties" />
        </Toolbar>;

        return <Toolbar multiline>
            <Item name="undo" />
            <Item name="redo" />
            <Item name="separator" />
            <Item
                name="size"
                acceptedValues={sizeValues}
            />
            <Item
                name="font"
                acceptedValues={fontValues}
            />
            <Item
                name="header"
                acceptedValues={headerValues}
            />
            <Item name="separator" />
            <Item name="bold" />
            <Item name="italic" />
            <Item name="strike" />
            <Item name="underline" />
            <Item name="separator" />
            <Item name="alignLeft" />
            <Item name="alignCenter" />
            <Item name="alignRight" />
            <Item name="alignJustify" />
            <Item name="separator" />
            <Item name="orderedList" />
            <Item name="bulletList" />
            <Item name="separator" />
            <Item name="color" />
            <Item name="background" />
            <Item name="separator" />
            {/* <Item name="link" /> */}
            <Item name="image" />
            <Item name="separator" />
            <Item name="clear" />
            {/* <Item name="codeBlock" /> */}
            {/* <Item name="blockquote" /> */}
            <Item name="separator" />
            <Item name="insertTable" />
            <Item name="deleteTable" />
            <Item name="insertHeaderRow" />
            <Item name="insertRowAbove" />
            <Item name="insertRowBelow" />
            <Item name="deleteRow" />
            <Item name="insertColumnLeft" />
            <Item name="insertColumnRight" />
            <Item name="deleteColumn" />
            <Item name="separator" />
            <Item name="cellProperties" />
            <Item name="tableProperties" />
        </Toolbar>;
    };

    if (isArabic)
        return (
            <div className="widget-container">
                <HtmlEditor
                    value={value}
                    onValueChanged={onValueChanged}
                    readOnly={isReadOnly}
                    rtlEnabled
                >
                    <MediaResizing enabled />
                    <TableContextMenu enabled />
                    <TableResizing enabled />
                    {renderToolBar()}
                </HtmlEditor>
            </div>

        );
    return (
        <div className="widget-container">
            <HtmlEditor
                value={value}
                onValueChanged={onValueChanged}
                onFocusOut={onBlur}
                readOnly={isReadOnly}
            >
                <MediaResizing enabled />
                <TableContextMenu enabled />
                <TableResizing enabled />
                {renderToolBar()}
            </HtmlEditor>
        </div>
    );
};

export default TextEditor;
