import React, { useState } from 'react';
import { Button, Grid, GridColumn, Modal, Segment, Header, Divider } from 'semantic-ui-react';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import { ErrorSegment, VideoPlayer } from '../../..';
import { Consts, Styles, Theme } from '../../../../res';
import PresentValues from '../../../../res/pdfs/presentvalues.pdf';
// import QuestionTranslationModal from '../QuestionTranslationModal';

const { noExtraSpaces, solidBtn, f_20_700_text } = Styles;

const ExamFooter = (props) => {
    const { Q, Qindex, isMockExam, isViewOnly, summary,
        onSelectQuestion, questions, t, onGoingExam,
        userOnFinishExam, examFinishingLoading } = props;

    // const isArabic = i18next.language === 'ar';


    const [showExplanation, setShowExplanation] = useState(false);
    const [showFinishConfirm, setShowFinishConfirm] = useState(false);
    // const [showTranslationModal, setShowTranslationModal] = useState(false);

    const buttonStyle = {
        boxShadow: 'none !important',
        borderRadius: 0,
        borderLeft: i18next.language === 'ar' ? 'solid white' : 'none',
        borderRight: i18next.language !== 'ar' ? 'solid white' : 'none',
        backgroundColor: 'transparent',
        color: 'black'
    };

    if (!Q) return null;

    return (
        <Segment inverted basic style={{ ...noExtraSpaces, backgroundColor: "#f2f2f2", borderTop: 'solid black 1px' }}>
            <Grid stackable columns='equal' verticalAlign='middle' style={noExtraSpaces}>
                <GridColumn style={noExtraSpaces}>
                    <a title={t('Attachments')} href={PresentValues} rel='noreferrer' target='_blank'>
                        <Button icon='attach' style={buttonStyle} />
                    </a>
                    {/* <Button icon='translate' content={t('Translate')} style={buttonStyle} onClick={() => setShowTranslationModal(true)} /> */}
                </GridColumn>

                <GridColumn textAlign='center' style={noExtraSpaces}>
                    {!summary.show && !isMockExam && (Q.answerText || Q.answerOptionId || isViewOnly) ? <Button icon='video play' labelPosition='left' content={t('Explanation')} onClick={() => setShowExplanation(true)} style={buttonStyle} /> : null}
                </GridColumn>

                <GridColumn textAlign='right' style={noExtraSpaces}>
                    {!summary.show && <Button.Group>
                        <Button disabled={Qindex <= 0} onClick={() => onSelectQuestion(questions[Qindex - 1])} labelPosition='left' icon='arrow left' content={t('Back').toUpperCase()} style={buttonStyle} />
                        <Button icon='th' content={t('Navigator')} onClick={() => summary.setShowSummary(!summary.show)} style={buttonStyle} />
                        <Button disabled={Qindex >= questions.length - 1} onClick={() => onSelectQuestion(questions[Qindex + 1])} labelPosition='right' icon='arrow right' content={t('Next').toUpperCase()} style={buttonStyle} />
                    </Button.Group>}

                    {summary.show &&
                        <>
                            <Button
                                content={t(onGoingExam.isViewOnly ? 'Exit' : 'Finish').toUpperCase()}
                                loading={examFinishingLoading}
                                onClick={() => onGoingExam.isViewOnly ? userOnFinishExam(onGoingExam) : setShowFinishConfirm(true)}
                                style={buttonStyle}
                            />
                            <Modal
                                size='tiny'
                                open={showFinishConfirm}
                                content={<Segment basic>
                                    <Header style={{ ...f_20_700_text, color: Theme.colors.PRIMARY_50 }}>{t('AreYouSureFinishExam')}</Header>
                                    <Divider hidden />
                                    <Button.Group fluid style={{ gap: 10 }}>
                                        <Button
                                            style={{ ...solidBtn, borderRadius: 6, backgroundColor: Theme.colors.ERROR_25, color: Theme.colors.ERROR_75 }}
                                            onClick={() => {
                                                userOnFinishExam(onGoingExam);
                                                setShowFinishConfirm(false);
                                            }}
                                        >
                                            {t('ExitExam')}
                                        </Button>
                                        <Button
                                            style={{ ...solidBtn, borderRadius: 6 }}
                                            onClick={() => setShowFinishConfirm(false)}
                                        >
                                            {t('Continue')}
                                        </Button>
                                    </Button.Group>
                                </Segment>}
                            />
                        </>
                    }
                </GridColumn>
            </Grid>

            <Modal
                onClose={() => setShowExplanation(false)}
                open={showExplanation}
                content={Q && Q.correctAnswer && Q.correctAnswer.explanations && Q.correctAnswer.explanations.videos.length ? <VideoPlayer kind={Consts.VIDEOS_KINDS.EXPLANATION} videoDetails={{ ...Q.correctAnswer.explanations.videos[0], ...Q }} onClose={() => setShowExplanation(false)} /> : <ErrorSegment text={t('ComingSoon')} icon='wait' />}
                basic={Q && Q.correctAnswer && Q.correctAnswer.explanations && Q.correctAnswer.explanations.videos.length ? true : false}
                size='large'
                centered
                closeIcon={!(Q && Q.correctAnswer && Q.correctAnswer.explanations && Q.correctAnswer.explanations.videos.length)}
                closeOnDimmerClick={false}
            />

            {/* <QuestionTranslationModal show={showTranslationModal} setShow={setShowTranslationModal}
                question={Q} lang={isArabic ? 'en' : 'ar'} examRoundId={onGoingExam.examRoundId} /> */}
        </Segment >
    );
};

export default withTranslation()(ExamFooter);