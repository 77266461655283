import React, { useState, useEffect } from 'react';
import { Card, Button, Image, Menu, Placeholder, Header, Icon, Label, Popup, Container, Segment } from 'semantic-ui-react';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Sidebar, useProSidebar } from 'react-pro-sidebar';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import { Languager } from '../../components';
import { signout } from '../../actions';
import { getHeaderBarData } from '../HeaderData';
import { Media } from '../../Media';
import CertificateCard from '../Cards/CertificateCard';
import avatarImage from '../../res/images/avatar.svg';
import iconImage from '../../res/images/icon.png';
import iconNotTextImage from '../../res/images/iconnotext.png';
import NotificationsList from '../Lists/NotificationsList';
import { Consts, Theme, Styles } from '../../res';
import PATHS from '../../Routers/PATHS';
import MobileNavBar from './MobileNavBar';
import history from '../../history';

const { container, solidBtn, basicBtn, f_12_400_text, f_12_700_text } = Styles;

const NavBar = (props) => {
    const { pathname } = useLocation();
    const { user, signout, onGoingExam, nots, notNormalSeen, certs, certLoading } = props;
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const { toggleSidebar, broken, toggled } = useProSidebar();
    const opened = ((broken && toggled) || (!broken));
    const openCloseSideBar = (status) => {
        if (broken) return toggleSidebar(status);
    };
    const [navBarImage, setNavBarImage] = useState(iconImage);

    useEffect(() => {
        document.addEventListener("scroll", () => {
            if (window.scrollY > 150) {
                setNavBarImage(iconNotTextImage);
            } else {
                setNavBarImage(iconImage);
            }
          });
        return () => document.removeEventListener('scroll', () => { });
    }, []);

    const renderAuthButtons = () => {
        if (!Consts.HIDE_MODE.MENUS_ALL) {
            if (!user) return <>
                <Menu.Item style={{ padding: 2 }}>
                    <Button as={Link} to={PATHS.LANDING_SIGNUP.URL} onClick={() => setShowMobileMenu(false)} content={t('SignUp').toUpperCase()} fluid style={{ ...basicBtn, ...f_12_400_text }} className="signupButton" />
                </Menu.Item>
                <Menu.Item style={{ padding: 2 }}>
                    <Button as={Link} to={PATHS.LANDING_SIGNIN.URL} onClick={() => setShowMobileMenu(false)} content={t('SignIn').toUpperCase()} fluid style={{ ...solidBtn, backgroundColor: Theme.colors.SECONDARY_50, ...f_12_400_text }} />
                </Menu.Item>
            </>;
            return <>
                <Popup
                    on='click'
                    flowing
                    hoverable
                    position='bottom center'
                    trigger={
                        <Menu.Item as='a'>
                            <Icon name={notNormalSeen > 0 ? 'bell' : 'bell outline'} color={notNormalSeen > 0 ? 'red' : 'grey'} size='large' />
                            {notNormalSeen > 0 && <Label color='red' circular size='tiny'>
                                {notNormalSeen}
                            </Label>}
                        </Menu.Item>
                    }
                >
                    <NotificationsList type={Consts.NOTIFICATIONS_TYPES.NORMAL} items={nots.filter(n => n.type === Consts.NOTIFICATIONS_TYPES.NORMAL)} />
                </Popup>

                <Menu.Item as={Link} to='/profile'>
                    <Header as='h5' textAlign='center'>
                        <Avatar src={user.coverImgUrl || avatarImage} round size='50' />
                        <Header.Content style={{ verticalAlign: 'middle', marginLeft: 5, marginRight: 10 }}>{user.firstName.toUpperCase()}</Header.Content>
                    </Header>
                </Menu.Item>
                <Menu.Item>
                    <Button style={{ margin: 3 }} onClick={signout} content={t('SignOut').toUpperCase()} fluid negative circular />
                </Menu.Item>
            </>;
        }
    };

    const renderMobileNav = () => {
        return (
            <Media lessThan='tablet'>
                {
                    (mediaClassNames, renderChildren) => {
                        return (
                            renderChildren && <>
                                <Sidebar renderExpandIcon={() => null} defaultCollapsed={false} breakPoint="always" rtl={!isArabic}>
                                    <MobileNavBar opened={opened} openCloseSideBar={openCloseSideBar} />
                                </Sidebar>

                                <Menu borderless fluid className={mediaClassNames} style={{ border: 'none', backgroundColor: 'white', boxShadow: 'none', margin: 0 }}>
                                    <Menu.Item>
                                        <Image style={{ transform: 'scale(1.5)' }} size='mini' src={iconImage} as={Link} to={PATHS.LANDING_HOME.URL} />
                                    </Menu.Item>

                                    <Menu.Menu position='right'>
                                        <Menu.Item icon={showMobileMenu ? 'times' : 'bars'} onClick={openCloseSideBar} />
                                    </Menu.Menu>


                                </Menu>
                            </>
                        );
                    }
                }
            </Media>
        );
    };

    const renderComputerNav = () => {
        return (
            <Media greaterThanOrEqual='tablet'>
                {
                    (mediaClassNames, renderChildren) => {
                        return (
                            renderChildren &&
                            <Segment basic style={{ padding: 0, margin: 0, backgroundColor: 'rgba(255, 255, 255, 0.7)', backdropFilter: 'blur(5px)', zIndex: 99999 }}>
                                <Container style={container}>
                                    <Menu borderless className={mediaClassNames} style={{ borderWidth: 0, boxShadow: 'none', padding: 5, backgroundColor: 'transparent' }}>
                                        <Menu.Item as={Link} to={PATHS.LANDING_HOME.URL} style={{ padding: 0, margin: 0 }}>
                                            <Image size='tiny' centered src={navBarImage} />
                                        </Menu.Item>
                                        {
                                            getHeaderBarData().map(item => item.id === 2 ?
                                                <Popup
                                                    on='hover'
                                                    position='bottom center'
                                                    hoverable
                                                    wide='very'
                                                    size='mini'
                                                    key={item.link}
                                                    trigger={
                                                        <Menu.Item
                                                            name={item.name}
                                                            as={Link}
                                                            to={item.link}
                                                            style={{ ...f_12_700_text, color: matchPath(pathname, { path: item.link, exact: true }) ? Theme.colors.SECONDARY_50 : Theme.colors.BLACK }}
                                                        />
                                                    }
                                                >
                                                    {certLoading ?
                                                        <Placeholder style={{ minWidth: '30vw' }}>
                                                            <Placeholder.Header>
                                                                <Placeholder.Line />
                                                                <Placeholder.Line />
                                                            </Placeholder.Header>
                                                            <Placeholder.Paragraph>
                                                                <Placeholder.Line length='medium' />
                                                                <Placeholder.Line length='short' />
                                                            </Placeholder.Paragraph>
                                                        </Placeholder> :
                                                        <Card.Group stackable centered itemsPerRow='3'>
                                                            {certs.slice(0, 6).map((c, index) => <CertificateCard short key={index} {...c} />)}
                                                            <Button style={{ ...solidBtn, margin: '5px' }} size='small'
                                                                onClick={() => history.push(item.link)} >{t('View All')}</Button>
                                                        </Card.Group>
                                                    }
                                                </Popup>
                                                : <Menu.Item
                                                    key={item.link}
                                                    name={item.name}
                                                    as={Link}
                                                    to={item.link}
                                                    style={{ ...f_12_700_text, color: matchPath(pathname, { path: item.link, exact: true }) ? Theme.colors.SECONDARY_50 : Theme.colors.BLACK }}
                                                />)
                                        }


                                        <Menu.Menu position='right'>
                                            <Languager />
                                            {renderAuthButtons()}
                                        </Menu.Menu>
                                    </Menu>
                                </Container>
                            </Segment>
                        );
                    }}
            </Media>
        );
    };

    const renderMenu = () => {
        if (onGoingExam?.isMockExam) return null;
        return (
            <>
                {renderComputerNav()}
                {renderMobileNav()}
            </>
        );
    };

    return (
        <div>
            <Helmet>
                <title>{`Learning-Go`}</title>
                <meta property='og:title' content={`Learning-Go`} />
                <meta property='og:url' content='https://www.learning-go.com.com' />
                <meta property='og:image' content='https://i.imgur.com/ODIwE4z.png' />
                <meta property="og:image:width" content="1792" />
                <meta property="og:image:height" content="963" />
                <meta property="og:image:alt" content="Learning-Go Logo" />
                <meta property="og:type" content="website" />
                <meta name="og:description" content="Learning-Go official site." />
            </Helmet>
            {renderMenu()}
        </div>
    );
};



const mapStateToProps = ({ auth, userExam, notifications, landingCerts }) => {
    const { user } = auth;
    const { onGoingExam } = userExam;
    const { certs, loading: certLoading } = landingCerts;
    const { notifications: nots, notNormalSeen } = notifications;
    return { user, onGoingExam, nots, notNormalSeen, certs, certLoading };
};

export default React.memo((connect(mapStateToProps, { signout })(NavBar)));
