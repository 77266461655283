import React, { useEffect, useState } from 'react';
import { Chart, CommonSeriesSettings, Export, Format, Label, LoadingIndicator, Series, ValueAxis } from 'devextreme-react/ui/chart';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Grid, GridColumn, Header, Segment, Button } from 'semantic-ui-react';
import request from '../../../api';
import { CircularProgressBar, ErrorSegment, Separator, UnderlinedHeader } from '../..';

const Results = ({ onGoingExam, onRetryExam, onGetOngoingExam, t, userExamInfoChanged }) => {
    const [res, setRes] = useState([]);
    const [error, setError] = useState('');
    const [meta, setMeta] = useState({});
    const { topicId } = useParams();

    useEffect(() => {
        const getExamResults = async () => {
            try {
                const { data: { data } } = await request.get(`/student/performance/round/exam/${onGoingExam.examRoundId}`);
                setMeta({ finishAt: data.finishAt, startAt: data.startAt });
                setRes([
                    { label: t('CorrectAnswers'), correctAnswers: data.correctQuestionsCount },
                    { label: t('WrongAnswers'), wrongAnswers: data.wrongQuestionsCount },
                    { label: t('NotAnswered'), notAnswered: data.unansweredQuestionsCount },
                    { label: t('FlaggedQuestions'), flaggedQuestions: data.flaggedQuestionsCount },
                    { label: t('Questions'), questions: data.totalQuestionsCount },
                ]);
            } catch (err) {
                setError(err);
            }
        };

        getExamResults();

    }, [onGoingExam.examRoundId, t]);

    const onUserRetryExam = async (retryWrong, retryFlagged, retryUnAnswered) => {
        userExamInfoChanged({ props: 'questions', value: [] });
        await onRetryExam(onGoingExam, retryWrong, retryFlagged, retryUnAnswered);
        await onGetOngoingExam(onGoingExam.examType, topicId ? parseInt(topicId) : null);
        userExamInfoChanged({ props: 'isFinished', value: false });
    };

    if (error) return <ErrorSegment />;

    const renderProgresses = () => {
        if (!res.length) return null;
        const spendMins = (new Date(meta.finishAt).getTime() - new Date(meta.startAt).getTime()) / 1000 / 60;
        const timePerQuestion = (spendMins / res[4].questions).toFixed(1);
        const grade = parseInt(res[0].correctAnswers / res[4].questions * 100);
        return (
            <Grid stackable columns='2' verticalAlign='middle' textAlign='center'>
                <GridColumn>
                    <CircularProgressBar text={`${grade}%`} value={grade} />
                    <Header as='h3' color='blue' content={t('OverallGrade')} />
                </GridColumn>

                <GridColumn>
                    <CircularProgressBar text={`${timePerQuestion} ${t('Min')}`} />
                    <Header as='h3' color='blue' content={t('TimeSpentPerQuestion')} />
                </GridColumn>
            </Grid>
        );
    };
    return (
        <>
            <UnderlinedHeader title={t('ExamResults').toUpperCase()} />
            <Chart
                id="chart"
                dataSource={res}
            >
                <CommonSeriesSettings
                    argumentField="label"
                    type="bar"
                    hoverMode="allArgumentPoints"
                    selectionMode="allArgumentPoints"
                    ignoreEmptyPoints
                >
                    <Label visible>
                        <Format type="fixedPoint" precision={0} />
                    </Label>
                </CommonSeriesSettings>
                <ValueAxis visible={false} />

                <Series
                    argumentField="label"
                    valueField="correctAnswers"
                    name={t('CorrectAnswers')}
                    color='green'
                />
                <Series
                    argumentField="label"
                    valueField="wrongAnswers"
                    name={t('WrongAnswers')}
                    color='red'
                />
                <Series
                    argumentField="label"
                    valueField="notAnswered"
                    name={t('NotAnswered')}
                    color='orange'
                />
                <Series
                    argumentField="label"
                    valueField="flaggedQuestions"
                    name={t('FlaggedQuestions')}
                    color='blue'
                />
                <Series
                    argumentField="label"
                    valueField="questions"
                    name={t('Questions')}
                    color='grey'
                />
                {/* <Legend verticalAlignment="bottom" horizontalAlignment="center" /> */}
                <Export enabled />
                <LoadingIndicator enabled text={t('Loading')} />
            </Chart>

            <Separator />
            {renderProgresses()}
            <Separator />
            {(res.length > 0 && !onGoingExam.isViewOnly) && <Segment basic textAlign='center'>
                {res[1].wrongAnswers > 0 && <Button positive content={t('RetryWrong')} onClick={() => onUserRetryExam(true)} />}
                {res[3].flaggedQuestions > 0 && <Button positive content={t('RetryFlagged')} onClick={() => onUserRetryExam(false, true)} />}
                {res[2].notAnswered > 0 && <Button positive content={t('RetryNotAnswered')} onClick={() => onUserRetryExam(false, false, true)} />}
            </Segment>}
        </>
    );
};

export default withTranslation()(Results);