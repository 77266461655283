import React from 'react';
import { Container, Icon, Segment, Table } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { Consts } from '../../../res';

const ExamSummary = ({ questions, onSelectQuestion, onFlagQuestion, summary, t, isMockExam, theme }) => {

    const printQuestionState = (q) => {
        if (!q.answerText && !q.answerOptionId && !q.answerJson) return t('NotAnsweredQuestion');
        if (q.answerOptionId && q.correctAnswer && !isMockExam && q.correctAnswer.correctAnswerOptionId === q.answerOptionId)
            return t('CorrectAnswer');
        else if (q.answerOptionId && q.correctAnswer && !isMockExam && q.correctAnswer.correctAnswerOptionId !== q.answerOptionId)
            return t('WrongAnswer');
        else if (q.answerOptionId && !q.correctAnswer && !isMockExam)
            return t('ClickToLoadQuestion');
        return t('Answered');
    };

    return (
        <Segment basic>
            <Container style={{ width: '80vw' }}>
                <Table celled unstackable compact>
                    <Table.Body>
                        {
                            questions.map((q, indx) => <Table.Row
                                key={q.questionId}
                                style={{ cursor: 'pointer' }}
                            >
                                <Table.Cell collapsing>
                                    <Icon link name='flag' onClick={() => onFlagQuestion(q)} style={{ color: q.isFlagged ? theme === Consts.EXAM_THEMES.CMA ? '#8CC63F' : '#639FD2' : '#ddd' }} />
                                </Table.Cell>

                                <Table.Cell
                                    onClick={() => {
                                        onSelectQuestion(q);
                                        if (window.ExamNav)
                                            window.ExamNav.goTo(Number(indx));
                                        summary.setShowSummary(false);
                                    }}
                                >
                                    {`${t('Question')} ${indx + 1}`}
                                </Table.Cell>

                                <Table.Cell >
                                    {printQuestionState(q)}
                                </Table.Cell>
                            </Table.Row>)
                        }
                    </Table.Body>
                </Table>
            </Container>
        </Segment>
    );
};

export default withTranslation()(ExamSummary);