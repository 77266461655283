import React from 'react';
import { Card, Image } from 'semantic-ui-react';
import { Consts } from '../../res';
import Logo from '../../res/images/logo.svg';

const NotificationCard = (props) => {
    const { title, body, image, type } = props;
    if (type === Consts.NOTIFICATIONS_TYPES.NORMAL) return (
        <Card fluid style={{ borderWidth: 0, boxShadow: 'none' }}>
            <Card.Content>
                <Image floated='left' size='tiny' src={image || Logo} />
                <Card.Header>{title}</Card.Header>
                <Card.Meta>{new Date().toLocaleString()}</Card.Meta>
                <Card.Description>{body}</Card.Description>
            </Card.Content>
        </Card>
    );
    return (
        <Card fluid style={{ borderWidth: 0, boxShadow: 'none' }}>
            <Image src={image || Logo} style={{ display: 'flex', height: 200, width: null }} />
            <Card.Content style={{ border: 'none' }}>
                <Card.Header>{title}</Card.Header>
                <Card.Meta>
                    <span className='date'>{new Date().toLocaleString()}</span>
                </Card.Meta>
                <Card.Description>
                    {body}
                </Card.Description>
            </Card.Content>
        </Card>
    );
};

export default NotificationCard;