import i18n from 'i18next';
import {
    USER_GET_ALL_MOCKS_ATTEMPTS,
    USER_GET_ALL_MOCKS_ATTEMPTS_FAIL,
    USER_GET_ALL_MOCKS_ATTEMPTS_FINISH,
    USER_GET_ALL_MOCKS_ATTEMPTS_START
} from '../types';
import request from '../../api';

export const userGetAllMocksAttempts = (courseId) => async dispatch => {


    dispatch({ type: USER_GET_ALL_MOCKS_ATTEMPTS_START });
    try {
        const { data } = await request.get(`/student/round/exam/mock/status/course/${courseId}`);
        dispatch({ type: USER_GET_ALL_MOCKS_ATTEMPTS, payload: data.data });
    } catch (err) {
        if (err.response) dispatch({ type: USER_GET_ALL_MOCKS_ATTEMPTS_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: USER_GET_ALL_MOCKS_ATTEMPTS_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: USER_GET_ALL_MOCKS_ATTEMPTS_FINISH });
    }
};
