import React from 'react';
import { Segment, Container, Header, Image } from 'semantic-ui-react';
import { HtmlEditor, TableContextMenu, TableResizing } from 'devextreme-react/ui/html-editor';
import McqsQuestionTemplate from '../Templates/McqsQuestionTemplate';
import TextQuestionTemplate from '../Templates/TextQuestionTemplate';
import McqsQuestionMockCMATemplate from '../Templates/McqsQuestionMockCMATemplate';
import { ErrorSegment, Separator } from '../../..';
import { Consts, Styles } from '../../../../res';
import iconImage from '../../../../res/images/logo.svg';
import { replaceFontFamily } from '../../../../helpers/general';

const { noExtraSpaces } = Styles;

const QuestionPage = (props) => {
    const { Q, onAnswerQuestion, loading, error, isMockExam, isViewOnly, user } = props;

    if (loading || !Q) return <Segment basic style={{ height: '40vh', ...noExtraSpaces }} loading={loading} />;
    if (error) return <ErrorSegment />;

    const renderMcqsTemplate = () => {
        if (Q.questionTypeId === Consts.QUESTIONS_TYPES.MCQS) {
            if (isMockExam)
                return <McqsQuestionMockCMATemplate Q={Q} onAnswerQuestion={onAnswerQuestion} isViewOnly={isViewOnly} />;
            else return <McqsQuestionTemplate Q={Q} onAnswerQuestion={onAnswerQuestion} isViewOnly={isViewOnly} />;
        }
        return null;

    };

    return (
        <Segment style={noExtraSpaces}>
            <Container style={{ width: '90vw', position: 'relative' }}>

                <Segment basic style={{ noExtraSpaces }}>
                    <div className="widget-container">
                        {Q.factId &&
                            <HtmlEditor value={replaceFontFamily(Q.factContent.trim())} readOnly style={{ backgroundColor: '#ECECEC', minHeight: '35vh', height: '35vh' }} className='resizo' >
                                <TableContextMenu enabled />
                                <TableResizing enabled />
                            </HtmlEditor>
                        }
                        <Separator h='1vh' />
                        <HtmlEditor value={replaceFontFamily(Q.questionText.trim())} readOnly style={{ backgroundColor: '#ECECEC' }} className='resizo' >
                            <TableContextMenu enabled />
                            <TableResizing enabled />
                        </HtmlEditor>
                    </div>
                    {renderMcqsTemplate()}
                    {Q.questionTypeId === Consts.QUESTIONS_TYPES.ESSAY && <TextQuestionTemplate Q={Q} onAnswerQuestion={onAnswerQuestion} isMockExam={isMockExam} isViewOnly={isViewOnly} />}
                </Segment>

                <div className='studentExamWatermark' style={{ top: '40%', left: '40%' }}>
                    <Image size='medium' src={iconImage} />
                </div>

                <Header
                    as='h1'
                    content={`${user.firstName} ${user.lastName} [${user.email}] ${user.contactNumber}`}
                    className='studentExamWatermark'
                />

            </Container>

        </Segment>
    );
};

export default QuestionPage;