import React, { useState, useEffect } from 'react';
import { Grid, GridColumn, Header, Segment, Table, TableBody } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import request from '../../../../api';
import Spinner from '../../../Operantinals/Spinner';
import ErrorSegment from '../../../Operantinals/ErrorSegment';
import CircularProgressBar from '../../../Operantinals/CircularProgressBar';
import UnderlinedHeader from '../../../Operantinals/UnderlinedHeader';
import Separator from '../../../Operantinals/Separator';
import { resolveExam } from '../../../../helpers/examHelpers';
import { Consts } from '../../../../res';

const ResultCia = ({ t, examRoundId }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [result, setResult] = useState([]);
    const selectedCourse = useSelector(({ userCourses }) => userCourses.selectedCourse);
    const examMetaDate = resolveExam(Consts.EXAM_TYPES.MOCK_PART1);
    const { questionsCount, totalDegree, successRatio } = examMetaDate;
    const passingScore = (totalDegree * successRatio).toFixed(0);

    useEffect(() => {
        getResult();
        //eslint-disable-next-line
    }, []);

    const getResult = async () => {
        setLoading(true);
        try {
            const { data: { data } } = await request(`/student/round/exam/${examRoundId}/results/level/units`);
            setResult(data);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const calcUnitRes = (u) => ((u.passedQuestionsCount / u.questionsCount) * 100).toFixed(1);;

    const renderUnitRes = (u) => {
        const res = calcUnitRes(u);
        if (res < 80)
            return `${t('BadUnitResult')}, ${t('Result')}: ${res}%`;
        else if (res >= 0 && res < 95)
            return `${t('MidUnitResult')}, ${t('Result')}: ${res}%`;
        else return `${t('GoodUnitResult')}, ${t('Result')}: ${res}%`;
    };

    const calcDegree = () => {
        const correctAnswersCount = result.reduce((acc, u) => u.passedQuestionsCount + acc, 0);
        return ((correctAnswersCount / questionsCount) * totalDegree).toFixed(1);
    };

    if (loading) return <Spinner />;
    if (error) return <ErrorSegment />;

    const Degree = calcDegree();
    const Passed = Degree > passingScore;
    return (
        <Segment basic textAlign='center'>
            <UnderlinedHeader title={t('ExamResults').toUpperCase()} />
            <Separator />
            <Grid stackable columns='2' textAlign='center' verticalAlign='middle'>
                <GridColumn width='6'>
                    <Header as='h3' color='red' content={selectedCourse.name} />
                    <CircularProgressBar text={`${Degree}`} value={Degree} color={Passed ? 'green' : 'red'} strokeWidth={20} />
                    <Header as='h3' color={Passed ? 'green' : 'red'} content={Passed ? t('Passed') : t('Failed')} />
                    <Header as='h5' color='black' content={`${t('PassingScore')} ${passingScore}`} />
                </GridColumn>

                <GridColumn width='10'>
                    <Table celled fixed textAlign='center' verticalAlign='middle' stackable >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell >{t('Unit')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('ResultAnalysis')}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <TableBody>
                            {
                                result.map(r => <Table.Row key={r.unitId} positive={calcUnitRes(r) >= 80} negative={calcUnitRes(r) < 80}>
                                    <Table.Cell >{r.unitName}</Table.Cell>
                                    <Table.Cell title={renderUnitRes(r)}>{renderUnitRes(r)}</Table.Cell>
                                </Table.Row>)
                            }
                        </TableBody>
                    </Table>
                </GridColumn>
            </Grid>
        </Segment>
    );
};

export default withTranslation()(ResultCia);