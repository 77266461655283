import React from 'react';
import { Container, Grid, GridColumn, Header, Icon, Segment } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import Stopwatch from '../../Timers/Stopwatch';
import Countdown from '../../Timers/Countdown';
import { Styles } from '../../../../res';
import { Separator } from '../../..';
import { getExamTypeString } from '../../../../helpers/examHelpers';
import request from '../../../../api';

const { noExtraSpaces } = Styles;

const ExamHeader = (props) => {
    const { t, course, courseStruct, user,
        Q, Qindex, questions, onFlagQuestion,
        onGoingExam, userOnFinishExam, isMockExam, summary, theme } = props;


    const getQUnitTopic = () => {
        let unitName = '', topicName = '';
        for (let i = 0; i < courseStruct.length; i++) {
            for (let j = 0; j < courseStruct[i].topics.length; j++) {
                if (courseStruct[i].topics[j].id === Q.topicId) {
                    topicName = `${courseStruct[i].topics[j].name} (${courseStruct[i].topics[j].rank})`;
                    unitName = `${courseStruct[i].name} (${courseStruct[i].rank})`;
                    return { unitName, topicName };
                }
            }
        }
        return { unitName, topicName };
    };

    const onPauseTimer = (isPause) => {
        const examRoundId = onGoingExam.examRoundId;

        request.post('/student/round/exam/pause', { examRoundId, isPause });
    };

    const renderTimer = () => {

        if (onGoingExam.isViewOnly)
            return <Header as='h2' color='red' textAlign='center'>{t('ViewOnly')}</Header>;

        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {isMockExam ?
                    <Countdown
                        expiryTimestamp={new Date().getTime() + (new Date(onGoingExam.finishDateTime) - new Date())}
                        onExpire={() => userOnFinishExam(onGoingExam)}
                        theme={theme}
                    />
                    : <Stopwatch
                        offsetTimestamp={new Date().getTime() + (new Date().getTime()
                            - new Date(onGoingExam.startDateTime).getTime())
                            - (onGoingExam.totalPauseSeconds * 1000)
                            - (onGoingExam.lastPauseTime ? (new Date().getTime() - new Date(onGoingExam.lastPauseTime).getTime()) : 0)
                        }
                        theme={theme}
                        onPauseChanged={onPauseTimer}
                        isAutoStart={!onGoingExam.lastPauseTime}
                    />}
                <Separator h='1vh' />
            </div>
        );
    };

    const renderPrimaryHeader = () => {
        return (
            <Segment basic style={{ margin: 0, padding: 5, backgroundColor: '#0370A8' }} inverted >
                <Container style={{ width: '95vw' }}>
                    <Grid columns='equal' textAlign='center' verticalAlign='middle' style={noExtraSpaces} stackable>

                        <GridColumn style={noExtraSpaces}>
                            <Header inverted as='h5' style={noExtraSpaces}>{`${t('Name')}: ${user.firstName} ${user.lastName}`}</Header>
                            <Header inverted as='h5' style={noExtraSpaces}>{`${user.email}`}</Header>
                        </GridColumn>

                        <GridColumn style={noExtraSpaces}>
                            <Header inverted as='h5' style={noExtraSpaces}>{`${course.certificateName} (${course.certificateCode})`}</Header>
                            <Header inverted as='h5' style={noExtraSpaces}>{course.name}</Header>
                        </GridColumn>

                        <GridColumn style={noExtraSpaces}>
                            {renderTimer()}
                            <Header inverted as='h5' style={noExtraSpaces}>{`${t('Question')}: ${Qindex + 1} / ${questions.length}`}</Header>
                        </GridColumn>

                    </Grid>
                </Container>
            </Segment>
        );
    };

    const renderSecondaryHeader = () => {
        const { unitName, topicName } = getQUnitTopic();
        return (
            <Segment basic style={{ margin: 0, padding: 5, backgroundColor: '#6EABD0' }} inverted>
                <Container style={{ width: '95vw' }}>
                    <Grid columns='equal' textAlign='center' style={noExtraSpaces} stackable verticalAlign='middle'>

                        <GridColumn style={noExtraSpaces}>
                            {!isMockExam && !summary.show && <Header inverted as='h5' style={noExtraSpaces}>{unitName}</Header>}
                            {!isMockExam && !summary.show && <Header inverted as='h5' style={noExtraSpaces}>{topicName}</Header>}
                        </GridColumn>

                        <GridColumn style={noExtraSpaces}>
                            {isMockExam && <Header inverted as='h5' style={noExtraSpaces}>{getExamTypeString(onGoingExam.examType, onGoingExam.orderNum)}</Header>}
                        </GridColumn>

                        <GridColumn style={noExtraSpaces}>
                            {!summary.show &&
                                <div onClick={() => onFlagQuestion(Q)}>
                                    <span
                                        style={{ textDecoration: Q.isFlagged ? 'line-through' : 'none', marginInline: 10, cursor: 'pointer' }}>
                                        {t('Flag')}
                                    </span>
                                    <Icon size='big'
                                        link name={Q.isFlagged ? 'flag' : 'flag outline'}
                                        style={{ color: Q.isFlagged ? 'yellow' : 'white', transform: 'rotate(20deg)' }}
                                    />
                                </div>
                            }
                        </GridColumn>
                    </Grid>
                </Container>
            </Segment >
        );
    };

    if (!Q) return null;

    return (
        <Segment basic style={noExtraSpaces}>
            {renderPrimaryHeader()}
            {renderSecondaryHeader()}
        </Segment>
    );
};

export default withTranslation()(ExamHeader);