import React from 'react';
import { Popup } from 'semantic-ui-react';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { Sidenav, Nav } from 'rsuite';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useProSidebar } from 'react-pro-sidebar';
import { Languager } from '../../components';
import { getHeaderBarData } from '../HeaderData';
import { signout } from '../../actions';
import { Consts, Theme } from '../../res';
import CloseIcon from '@rsuite/icons/Close';
import PATHS from '../../Routers/PATHS';

const MobileNavBar = (props) => {
    const { openCloseSideBar, opened } = props;
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const { broken } = useProSidebar();

    return (
        <Sidenav expanded={opened} style={{ minHeight: '100vh', backgroundColor: Theme.colors.PRIMARY_50 }}>
            <Sidenav.Body style={{ backgroundColor: Theme.colors.PRIMARY_50 }}>
                <Nav style={{ backgroundColor: Theme.colors.PRIMARY_50, color: Theme.colors.WHITE }}>

                    <Nav.Item
                        style={{ color: Theme.colors.WHITE, backgroundColor: Theme.colors.PRIMARY_50, padding: "1vh 2vw 3vh" }}
                        onClick={broken ? () => openCloseSideBar(false) : undefined}
                    >
                        <div style={{ textAlign: 'right' }}>
                            <CloseIcon />
                        </div>
                    </Nav.Item>

                    {!Consts.HIDE_MODE.MENUS_ALL &&
                        <Nav.Item
                            style={{ color: Theme.colors.WHITE, backgroundColor: Theme.colors.PRIMARY_50, padding: "1vh 2vw" }}
                            as={Link}
                            to={PATHS.LANDING_SIGNIN.URL}
                            onClick={broken ? () => openCloseSideBar(false) : undefined}
                        >
                            <div style={{ padding: 10, textAlign: 'center', backgroundColor: Theme.colors.SECONDARY_50, borderRadius: 10, border: `2px solid ${Theme.colors.SECONDARY_50}` }}> {t('SignIn').toUpperCase()}</div>
                        </Nav.Item>
                    }
                    {
                        !Consts.HIDE_MODE.MENUS_ALL &&         
                        <Nav.Item
                            style={{ color: Theme.colors.WHITE, backgroundColor: Theme.colors.PRIMARY_50, padding: "1vh 2vw" }}
                            as={Link}
                            to={PATHS.LANDING_SIGNUP.URL}
                            onClick={broken ? () => openCloseSideBar(false) : undefined}
                        >
                            <div style={{ padding: 10, textAlign: 'center', border: "2px solid white", borderRadius: 10 }}> {t('SignUp').toUpperCase()}</div>
                        </Nav.Item>
                    }
                
                    <Popup
                        on='click'
                        flowing
                        hoverable
                        trigger={
                            <Nav.Item
                                style={{ color: Theme.colors.WHITE, backgroundColor: Theme.colors.PRIMARY_50, padding: "2vh 2vw" }}
                            >
                                <div style={{ textAlign: 'center' }}>
                                    {t('Language')}
                                </div>
                            </Nav.Item>
                        }
                    >
                        <Languager isMenu />
                    </Popup>

                    {getHeaderBarData().map(item =>
                        <Nav.Item
                            style={{ color: Theme.colors.WHITE, backgroundColor: Theme.colors.PRIMARY_50, paddingLeft: '5vw' }}
                            key={item.id}
                            as={Link}
                            to={item.link}
                            eventKey={item.name}
                            onClick={broken ? () => openCloseSideBar(false) : undefined}
                            active={matchPath(pathname, { path: item.link, exact: true })?.isExact}
                        >
                            {item.name}
                        </Nav.Item>
                    )}

                </Nav>
            </Sidenav.Body>
        </Sidenav >
    );
};



export default connect(null, { signout })(MobileNavBar);
