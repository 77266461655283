import {
    USER_GET_ALL_MOCKS_ATTEMPTS,
    USER_GET_ALL_MOCKS_ATTEMPTS_FAIL,
    USER_GET_ALL_MOCKS_ATTEMPTS_FINISH,
    USER_GET_ALL_MOCKS_ATTEMPTS_START
} from '../../actions/types';

const INITIAL_STATE = {
    mocksAttempts: null,
    error: '',
    loading: false,
};


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_GET_ALL_MOCKS_ATTEMPTS_START:
            return { ...state, loading: true };
        case USER_GET_ALL_MOCKS_ATTEMPTS_FINISH:
            return { ...state, loading: false };
        case USER_GET_ALL_MOCKS_ATTEMPTS_FAIL:
            return { ...state, error: { [action.payload.props]: action.payload.value } };
        case USER_GET_ALL_MOCKS_ATTEMPTS:
            return { ...state, mocksAttempts: action.payload };
        default:
            return state;
    }
};

