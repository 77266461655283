import {
    USER_EXAM_INFO_CHANGED,
    USER_GET_EXAM_QUESTIONS_START,
    USER_GET_EXAM_QUESTIONS_FAIL,
    USER_GET_EXAM_QUESTIONS_FINISH,
    USER_GET_EXAM_QUESTIONS,
    USER_GET_EXAM_QUESTION_OPTIONS_START,
    USER_GET_EXAM_QUESTION_OPTIONS_FAIL,
    USER_GET_EXAM_QUESTION_OPTIONS_FINISH,
    USER_GET_EXAM_QUESTION_OPTIONS,
    USER_EXAM_ANSWER_QUESTION,
    USER_EXAM_FLAG_QUESTION,
    USER_EXAM_SELECT_QUESTION,
    USER_GET_ONGOING_EXAM,
    USER_GET_ONGOING_EXAM_START,
    USER_GET_ONGOING_EXAM_FAIL,
    USER_GET_ONGOING_EXAM_FINISH,
    USER_ON_EXAM_START,
    USER_ON_EXAM_FINISH,
    USER_ON_EXAM_START_LOADING_START,
    USER_ON_EXAM_START_LOADING_FINISH,
    USER_ON_EXAM_FINISH_LOADING_START,
    USER_ON_EXAM_FINISH_LOADING_FINISH
} from '../../actions/types';

const INITIAL_STATE = {
    questions: [],
    error: '',
    loading: false,
    Q: null,
    isFinished: false,
    onGoingExam: null,
    onGoingExamLoading: false,
    onGoingExamError: '',
    questionOptionsError: '',
    questionOptionsLoading: false,
    examStartingLoading: false,
    examFinishingLoading: false,
};


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_EXAM_INFO_CHANGED:
            return { ...state, [action.payload.props]: action.payload.value };
        case USER_GET_EXAM_QUESTIONS_START:
            return { ...state, loading: true };
        case USER_GET_EXAM_QUESTIONS_FINISH:
            return { ...state, loading: false };
        case USER_GET_EXAM_QUESTIONS_FAIL:
            return { ...state, error: { [action.payload.props]: action.payload.value } };
        case USER_GET_EXAM_QUESTIONS:
            return { ...state, questions: action.payload };
        case USER_GET_EXAM_QUESTION_OPTIONS_START:
            return { ...state, questionOptionsLoading: true };
        case USER_GET_EXAM_QUESTION_OPTIONS_FINISH:
            return { ...state, questionOptionsLoading: false };
        case USER_GET_EXAM_QUESTION_OPTIONS_FAIL:
            return { ...state, questionOptionsError: { [action.payload.props]: action.payload.value } };
        case USER_GET_EXAM_QUESTION_OPTIONS:
            return { ...state, Q: { ...state.Q, options: action.payload } };
        case USER_EXAM_SELECT_QUESTION:
            return { ...state, Q: action.payload, questions: updateQuestion(state.questions, action.payload) };
        case USER_EXAM_ANSWER_QUESTION:
            return { ...state, Q: action.payload, questions: updateQuestion(state.questions, action.payload) };
        case USER_EXAM_FLAG_QUESTION:
            return { ...state, /*Q: action.payload,*/ questions: updateQuestion(state.questions, state.Q) };
        case USER_GET_ONGOING_EXAM_START:
            return { ...state, onGoingExamLoading: true };
        case USER_GET_ONGOING_EXAM_FINISH:
            return { ...state, onGoingExamLoading: false };
        case USER_GET_ONGOING_EXAM_FAIL:
            return { ...state, onGoingExamError: { [action.payload.props]: action.payload.value } };
        case USER_GET_ONGOING_EXAM:
            return { ...state, onGoingExam: action.payload[0] };
        case USER_ON_EXAM_START_LOADING_START:
            return { ...state, examStartingLoading: true };
        case USER_ON_EXAM_START_LOADING_FINISH:
            return { ...state, examStartingLoading: false };
        case USER_ON_EXAM_FINISH_LOADING_START:
            return { ...state, examFinishingLoading: true };
        case USER_ON_EXAM_FINISH_LOADING_FINISH:
            return { ...state, examFinishingLoading: true };
        case USER_ON_EXAM_START:
            return { ...state, onGoingExam: action.payload };
        case USER_ON_EXAM_FINISH:
            return { ...state, ...INITIAL_STATE };
        default:
            return state;
    }
};

const updateQuestion = (questions, Q) => questions.map(q => q.questionId === Q.questionId ? Q : q);
